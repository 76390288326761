import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { MobileNavigationComponent } from '../mobile-navigation/mobile-navigation.component';

@Component({
  selector: 'app-custom-hamburger',
  templateUrl: './custom-hamburger.component.html'
})
export class CustomHamburgerComponent implements OnInit {
  themeClass:any = this.document.getElementsByTagName('app-root')[0].classList;
  mobileNavigationDialog: any;
  currentThemeClass: any;
  
  @ViewChild('dialogRef')
  dialogRef!: TemplateRef<any>;

  constructor(public dialog: MatDialog, public breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document, private currentTheme: CurrentThemeService,
  ) {
    this.currentThemeClass = this.currentTheme.getCurrentTheme();
  }

  openCategoryNavigation() {
    this.mobileNavigationDialog = this.dialog.open(MobileNavigationComponent, {
      position: {
        left: '0px',
        top: '0px'
      },
      panelClass: [this.currentThemeClass, 'mobile-navigation'],
      autoFocus: false
    });
  }
  
  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 1024px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        if(this.mobileNavigationDialog) {
          this.mobileNavigationDialog.close();
        }       
      }
    });
  }

}
