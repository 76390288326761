import { DigitalMarketingModule } from './feature/pages/digital-marketing/digital-marketing.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, WindowRef } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { NonLoggedInUserGuard } from 'src/app/core/guards/non-logged-in-user.guard';
import { AdvanceEbillDetailsGuard } from './core/guards/advance-ebill-details.guard';
import { AsmGuard } from './core/guards/asm.guard';
import { BackordersGuard } from './core/guards/backorders.guard';
import { GtmPageGuard } from './core/guards/gtm-page.guard';
import { ItemPurchaseActivityGuard } from './core/guards/item-purchase-activity.guard';
import { ObMuBoAuthGuard } from './core/guards/ob-mu-bo-auth.guard';
import { SsrIntermittentComponent } from './feature/components/ssr-intermittent/ssr-intermittent.component';
import { MyAccountComponent } from './feature/pages/my-account/my-account.component';
import { SalesRepComponent } from './feature/pages/sales-rep/sales-rep.component';
import { DefaultTemplateComponent } from './shared/components/default-template/default-template.component';

// *** sample code for writing a Routing configuration starts** //
const STATIC_ROUTES: Routes = [
  {
  matcher:(url) =>{
    const dns_type = BoxoutRoutingModule.windowReference?.document?.location?.hostname;
    if(dns_type.includes('portal.myboxout') && !url[0]?.path.includes('contentpage') && !url[0]?.path.includes('ssr-intermittent')) {
      return url.length ? { consumed: [] } : { consumed: url };
    }
    return null;
  },
  loadChildren: () =>
    import('./feature/pages/portal-boxout/portal-boxout.module').then(
      (m) => m.PortalBoxoutModule
    )
  },
  {
    path: 'ssr-intermittent',
    component: SsrIntermittentComponent,
    canActivate: [CmsPageGuard]
  },
  {
    path: 'advanced-ebill-report',
    data: {
      pageLabel: 'advanced-ebill-report',
      breadcrumb: 'Advanced eBill Report',
      title: 'Advanced eBill Report',
    },
    loadChildren: () =>
      import('./feature/pages/advanced-ebill/advanced-ebill.module').then(
        (m) => m.AdvancedEbillModule
      ),
    canActivate: [
      CmsPageGuard,
      AuthGuard,
      ObMuBoAuthGuard,
      AdvanceEbillDetailsGuard,
    ],
  },
  {
    path: 'my-account',
    data: { pageLabel: 'my-account', breadcrumb: 'My Account', title:'My Account' },
    component: MyAccountComponent,
    loadChildren: () =>
      import('./feature/pages/my-account/my-account.module').then(
        (m) => m.MyAccountModule
      ),
    canActivate: [CmsPageGuard, AuthGuard, ObMuBoAuthGuard],
  },
  {
    path: 'login',
    data: { pageLabel: 'login' , title: 'Log In'},
    loadChildren: () =>
      import('./feature/pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [CmsPageGuard, NonLoggedInUserGuard],
  },
  {
    path: '',
    data: { pageLabel: 'homepage', breadcrumb: 'Home' , title:'Home' },
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard, ObMuBoAuthGuard,GtmPageGuard],
  },
  {
    path: 'homepage',
    data: { pageLabel: 'homepage', breadcrumb: 'Home', title:'Home' },
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard, ObMuBoAuthGuard,GtmPageGuard],
  },
  {
    path: 'search',
    data: { pageLabel: '/search', breadcrumb: 'Search' },
    loadChildren: () =>
      import('./feature/pages/product-search/product-search.module').then(
        (m) => m.ProductSearchModule
      ),
    canActivate: [CmsPageGuard, ObMuBoAuthGuard,GtmPageGuard],
  },
  {
    path: 'register',
    data: { pageLabel: 'register', breadcrumb: 'Register', title:'Register' },
    loadChildren: () =>
      import('./feature/pages/register/register.module').then(
        (m) => m.RegisterModule
      ),
    canActivate: [CmsPageGuard, ObMuBoAuthGuard, NonLoggedInUserGuard],
  },
  {
    path: 'quickOrder',
    data: { pageLabel: 'quickOrder', breadcrumb: 'QuickOrder' , title:'My Quick Order' },
    loadChildren: () =>
      import('./feature/pages/quick-order/quick-order.module').then(
        (m) => m.QuickOrderModule
      ),
    canActivate: [CmsPageGuard, AuthGuard, ObMuBoAuthGuard],
  },
  {
    path: 'orderConfirmation/:id',
    data: { pageLabel: 'orderConfirmation', breadcrumb: 'orderConfirmation' },
    loadChildren: () =>
      import(
        './feature/pages/my-account/sub-pages/place-order-confirmation/place-order-confirmation.module'
      ).then((m) => m.PlaceOrderConfirmationModule),
    canActivate: [CmsPageGuard, AuthGuard, ObMuBoAuthGuard],
  },
  {
    path: 'rapidOrder',
    data: {
      pageLabel: 'rapidOrder',
      breadcrumb: 'RapidOrder',
      title:'My Rapid Order'
    },
    loadChildren: () =>
      import('./feature/pages/rapid-order/rapid-order.module').then(
        (m) => m.RapidOrderModule
      ),
    canActivate: [CmsPageGuard, AuthGuard, ObMuBoAuthGuard],
  },
  {
    path: 'brands',
    data: { pageLabel: 'brand', breadcrumb: 'Brands', title:'Brands'},
    loadChildren: () =>
      import('./feature/pages/brands/brands/brand.module').then(
        (m) => m.BrandsModule
      ),
    canActivate: [CmsPageGuard, ObMuBoAuthGuard , GtmPageGuard],
  },
  {
    path: 'itemPurchaseActivitySearch',
    data: {
      pageLabel: 'itemPurchaseActivitySearch',
      breadcrumb: 'Items Purchase Activity Report',
      title: 'Items Purchase Activity Report',
    },
    loadChildren: () =>
      import(
        './feature/pages/item-purchase-activity/item-purchase-activity.module'
      ).then((m) => m.ItemPurchaseActivityModule),
    canActivate: [
      CmsPageGuard,
      AuthGuard,
      ObMuBoAuthGuard,
      ItemPurchaseActivityGuard,
    ],
  },
  {
    path: 'productDetails/:productCode',
    data: { pageLabel: 'productDetails', breadcrumb: 'Product Details' },
    loadChildren: () =>
      import(
        './feature/pages/product-details-page/product-details-page.module'
      ).then((m) => m.ProductDetailsModule),
    canActivate: [CmsPageGuard, ObMuBoAuthGuard],
  },
  {
    path: 'all-categories',
    data: { pageLabel: 'all-categories', breadcrumb: 'All Categories', title: 'All Categories' },
    loadChildren: () =>
      import(
        './feature/pages/category-listing-page/category-listing-page.module'
      ).then((m) => m.CategoryListingPageModule),
    canActivate: [CmsPageGuard, ObMuBoAuthGuard],
  },
  {
    path: 'categoryCode/:categoryCode',
    data: { pageLabel: 'categoryCode', breadcrumb: 'Category Code' },
    loadChildren: () =>
      import(
        './feature/pages/product-search-category/product-search-category.module'
      ).then((m) => m.ProductSearcCategoryhModule),
    canActivate: [CmsPageGuard, ObMuBoAuthGuard ,GtmPageGuard],
  },
  {
    path: 'cart',
    data: { pageLabel: 'cart', breadcrumb: 'Cart', title:'View Cart' },
    loadChildren: () =>
      import('./feature/pages/cart/cart.module').then((m) => m.CartModule),
    canActivate: [CmsPageGuard, AuthGuard, ObMuBoAuthGuard],
  },
  {
    path: 'checkout/multi',
    data: { pageLabel: 'checkoutPage', breadcrumb: 'Checkout', title:'Checkout' },
    loadChildren: () =>
      import('./feature/pages/product-checkout/product-checkout.module').then(
        (m) => m.ProductCheckoutModule
      ),
    canActivate: [CmsPageGuard, AuthGuard, ObMuBoAuthGuard],
  },
  {
    path: 'backorder-report',
    data: { pageLabel: '/backorder-report', breadcrumb: 'Backordered Report', title: 'Backordered Report' },
    loadChildren: () =>
      import('./feature/pages/backorder-report/backorder-report.module').then(
        (m) => m.BackorderReportModule
      ),
    canActivate: [CmsPageGuard, BackordersGuard, AuthGuard, ObMuBoAuthGuard],
  },
  {
    path: 'reorder/:id',
    data: { pageLabel: '/reorder', breadcrumb: 'Reorder', title:'Reorder' },
    loadChildren: () =>
      import('./feature/pages/reorder/reorder.module').then(
        (m) => m.ReorderModule
      ),
    canActivate: [CmsPageGuard, AuthGuard, ObMuBoAuthGuard],
  },
  {
    path: 'brand/:code',
    data: { pageLabel: 'brand', breadcrumb: 'Brand' },
    loadChildren: () =>
      import('./feature/pages/brand-search/brand-search.module').then(
        (m) => m.BrandSearchModule
      ),
    canActivate: [CmsPageGuard, ObMuBoAuthGuard,GtmPageGuard],
  },
  {
    path: 'digitalMarketing',
    data: { pageLabel: 'digitalMarketing' },
    loadChildren: () => import('./feature/pages/digital-marketing/digital-marketing.module').then((m) => m.DigitalMarketingModule),
    canActivate: [CmsPageGuard, ObMuBoAuthGuard, GtmPageGuard],
  },
  {
    path: 'sales-rep/login',
    data: { pageLabel: 'sales-rep/login', title:'Sales Rep Log In' },
    loadChildren: () =>
      import(
        './feature/pages/sales-rep/sr-login-page/sr-login-page.module'
      ).then((m) => m.SrLoginPageModule),
    canActivate: [CmsPageGuard, NonLoggedInUserGuard],
  },
  {
    path: 'sales-rep',
    data: { pageLabel: 'sales-rep' , title:'Sales Rep'},
    component: SalesRepComponent,
    loadChildren: () =>
      import('./feature/pages/sales-rep/sales-rep.module').then(
        (m) => m.SalesRepModule
      ),
    canActivate: [CmsPageGuard, AsmGuard],
  },
  {
    path: 'reset-password',
    data: {
      pageLabel: 'reset-password', title:'Reset Password'
    },
    loadChildren: () =>
      import('./feature/pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [CmsPageGuard],
  },
  {
    path: 'login/:code',
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard, NonLoggedInUserGuard, ObMuBoAuthGuard , GtmPageGuard]
  },
  {
    path: 'contentpage/:code',
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard, ObMuBoAuthGuard , GtmPageGuard]
  },
  // {
  //   path: '**',
  //   component: DefaultTemplateComponent,
  //   canActivate: [CustomCmsPageGuard],
  // },

    /** Smart edit SEO changes -starts.. DO NOT DELETE */
    {
      path: 'cx-preview',
      component: PageLayoutComponent,
      canActivate: [CmsPageGuard]
    },
    {
      path: '**',
      component: DefaultTemplateComponent,
      canActivate: []
  
    },
  /** Smart edit SEO changes -starts.. DO NOT DELETE */


  // DO NOT DELETE - Needed for 404 route
  // {
  //   path: '**',
  //   data: {
  //     pageLabel: 'pageNotFound'
  //   },
  //   loadChildren: () =>
  //     import('./feature/pages/page-not-found/page-not-found.module').then(
  //       (m) => m.PageNotFoundModule
  //     ),
  //   canActivate: [CmsPageGuard],
  // }
];
// *** sample code for writing a Routing configuration ends** //
 
@NgModule({
  declarations: [],
  imports: [
    // *** sample code for writing a Routing configuration starts** //
    RouterModule.forChild(STATIC_ROUTES),
    // *** sample code for writing a Routing configuration ends** //
  ],
})
 
export class BoxoutRoutingModule {
  static windowReference: any;
  constructor(window: WindowRef) {
    BoxoutRoutingModule.windowReference = window;
  }
}
 
