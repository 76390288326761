import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginDialogComponent } from 'src/app/shared/components/login-dialog/login-dialog.component';
import { HeaderSitelinksComponent } from '../../../core/header/components/header-sitelinks/header-sitelinks.component';
import { CurrentThemeService } from '../../services/common/current-theme.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent {
  theme: any = this.document.getElementsByTagName('app-root')[0].classList;
  themeClass: any;
  constructor(public dialog: MatDialog,
    public dialogRef:MatDialogRef<HeaderSitelinksComponent>,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private currentTheme: CurrentThemeService) {
      this.themeClass = this.currentTheme.getCurrentTheme();
    }

  //sign in click function to trigger login dialog
  signInClick() {
    /** Removing the b2bUnit from session storage on signin click*/
    if (localStorage.getItem("b2bUnit")) {
      localStorage.removeItem("b2bUnit");
    }

    if (localStorage.getItem("multipleB2bUnitFlag")) {
      localStorage.removeItem("multipleB2bUnitFlag");
    }

    const dialogRef = this.dialog.open(LoginDialogComponent,
      {
        panelClass: [this.themeClass, 'custom-dialog-size'],
        autoFocus: false,
        disableClose: true
      });
    if (Object.keys(this.dialogRef).length !== 0) {
      this.dialogRef.close();
    }
  }


}
