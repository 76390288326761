export const global = {
  global: {
    //footer distribution sign up component
    footerSignUp: {
      emailTitle: 'Sign Up For Email',
      emailAddressLabel: 'Email Address *',
      footerPrivacyPolicy: 'Privacy Policy',
      footerTermsCond: 'Terms & Conditions'

    },
    //footer powered by component
    footerPoweredBy: {
      footerLogoAltText: 'poweredBy logo',
    },
    signIn: {
      signInText: 'Sign In',
      paraText: 'Access your {{siteName}} Account',
      paraSubText: 'Account',
      emailLabel: 'Email or user ID *',
      emailRequiredError: 'Please enter your email address',
      emailInvalidError: 'Please enter a valid email address',
      signIn: 'Sign In',
      subTextOne: 'By selecting Sign In, you agree to our',
      subTextTwo: 'Terms and have read and',
      subTextThree: 'acknowledge our Global Privacy',
      subTextFour: 'Statement.',
      forgotPassword: 'Forgot Password?',
      sendLink: 'Send Link',
      //newBoxout: 'New to Boxout Distribution',
      //solution: 'Solutions?',
      newTo: 'New to {{siteName}} ?',
      createAccount: 'Create an account',
      recaptcha: 'Invisible reCAPTCHA by Google ',
      and:'and ',
      privacy:'Privacy Policy ',
      termsOfUse:'Terms of Use , '
    },
    password: {
      passwordText: 'Enter Password',
      paraText: 'Please enter your password for',
      changeUser: 'Change UserID',
      passwordLabel: 'Password *',
      passwordRequiredError: 'Please enter your password',
      passwordInvalidError: 'Your username or password was incorrect',
      continueText: 'Continue',
      or: 'OR',
      forgotPassword: 'Forgot Password?',
      sendLink: 'Send Link',
      sslLabel: 'We use the best SSL technology to ensure our',
      sslLabelOne: 'site is secure - Norton Security Services.',
      recaptcha: 'Invisible reCAPTCHA by Google ',
      and:'and ',
      privacy:'Privacy Policy ',
      termsOfUse:'Terms of Use , '
    },
    forgotPassword: {
      forgotPasswordText: 'Forgot your password?',
      paraText: "We'll email you a verification code to reset",
      paraSubText: 'your password.',
      emailLabel: 'Email or user ID *',
      emailRequiredError: 'Please enter your email address',
      emailInvalidError: 'Please enter a valid email address',
      sendText: 'Send Password Reset Link',
      or: 'OR',
      rememberPass: 'Remember your Password?',
      logIn: 'Log In',
      tips: 'Password Reset Tips',
      tipsTextOne:
        'Please check your inbox for password reset instructions.',
      tipsTextTwo:
        'Make sure to double-check your spam and promotions folders.',
      trouble: 'Still having trouble?',
      instructons: 'Click here to receive additional support',
      recaptcha: 'Invisible reCAPTCHA by Google ',
      and:'and ',
      privacy:'Privacy Policy ',
      termsOfUse:'Terms of Use.',
      requestRecieved:"Request Received",
      requestText:"We will send a password reset email to",
      backBtn:"Back to Previous Screen"
    },
    resetPassword: {
      newPassword: "New Password",
      reenterPassword:  "Reenter New Password",
      createPassword: "Create Password",
      heading: "Password Reset",
      informativeText1: "Almost done!",
      informativeText2: "For your security, please change your password to something you haven’t used before.",
      informativeText3: "We use the best SSL technology to ensure our site is secure — Norton Security Services.",
      errorAlertMsg: 'Something went wrong!',
      successAlertMsg: 'Done! The information has been updated',
    },
    pageNotFound: {
      heading: "404",
      subHeading: "Page Not Found",
      informativeText1: "We’re sorry the page you requested could not be found.",
      informativeText2: "Try going back to our Homepage or click the “live Help” button on the top right side of the page and we’ll hellp you find what you need."
    },
    switchAcoount: {
      headline: 'Pick Your Account',
      paraText: 'You have ',
      paraTextOne: ' Accounts on Record. Please select',
      paraSubText: 'the account you would like to access.',
    },

    //recommended address component
    recommendedAddress: {
      title: 'Please verify the address submitted',
      errorOne:
        'The address entered below was not verified by USPS.Please check it for accuracy. Note that there could be delayed packages due to invalid addresses.',
      addressSubmitted: 'SUBMITTED',
      editAddress: 'Edit Address',
      useAddress: 'Use This Address',
      addressRecommended: 'RECOMMENDED',
      errorTwo:
        'Important: If this address is used you may be charged services fees and handling for any misshipments or returns',
    },

    //reusable address form component
    reusableAddressForm: {
      addressOneTitle: 'Address 1 *',
      addressOneRequired: 'Please enter your address',
      addressTwoTitle: 'Apt, Suite, etc. (Optional Address 2)',
      cityTitle: 'City *',
      cityRequired: 'Please enter your city',
      stateTitle: 'State *',
      stateRequired: 'Please select the state',
      zipCodeTitle: 'ZIP Code *',
      zipCodeRequired: 'Please enter your postal code',
      zipCodeLength: 'Your postcode must be 5 to 9 characters long',
      zipCodePattern: 'Please enter a valid postal code',
      countryTitle: 'Country *',
      countryRequired: 'Please select the Country',
    },
    reusableSignInSecurity: {
      changePassword: 'Change Password',
      createPasword: 'Create Password',
    },
  },

  header: {
    viewCartLabel: 'View Cart items',
    businessInfoTextforSpaandElivate:'In order to access professional pricing and to purchase certain products that may require a prescription and / or are restricted, please submit your license number, or state issued license number.',
    account: 'Account',
    quickOrder: 'Quick Order',
    yourCart: 'your cart',
    exploreAll: 'Explore All',
    signIn: 'SIGN IN',
    registerAccount: 'REGISTER FOR AN ACCOUNT',
    menu: 'Menu',
    categories: 'Categories',
    yourRep: 'YOUR REP:',
    acct: 'ACCT:',
    logout: '[ Logout ]',
    accountProfile: 'Account Profile Icon',
    salesRepIcon: 'Sales Rep Icon',
    liveSupport: 'Live Support',
    chat: 'Chat',
    aboutboxout: 'About {{siteName}}',
    accountDownArrow: 'Change Account Down Arrow',
    quickOrderDownArrow: 'Quick Order Down Arrow',
    search: 'Search',
    searchBar: 'Search Bar',
    item: 'Item #',
    qty: 'QTY',
    addCart: 'Add to Cart',
    tryOut: 'Try out Expanded Quick Order & Lists',
    shopAll: 'Shop All {{ navNode}}',
    emptyCart: ' Your Cart is Empty',
    remove: 'remove',
    cart: 'Cart',
    close: 'close',
    shipping: 'Shipping',
    checkout: 'Calculated at Checkout',
    subtotal: 'Subtotal',
    viewCart: ' View Cart',
    checkoutDesc: 'Shipping, taxes & discount codes calculated at checkout',
  },
  brands: {
    title: 'Brands',
    ourBrands: 'Our Brands',
    new: 'New',
    to: ' To '
  },
  orderApproval: {
    title: 'Orders Requiring Review',
    subTitle: 'These orders have been submitted by users and require review.',
    date:'Date',
    user:'User',
    comments:'Comments',
    status:'Status',
    revision:'Notes & Revision History',
    emailSuccess:"Order confirmation email has been sent",
    inStore: 'In Store',
    truckLTL: 'Truck - LTL',
  },
  notifyStock: {
    title: 'Notify me when back in stock',
    sku: 'SKU',
    notifyYou: 'We will notify you at this contact info:',
    emaiAddr: 'Email Address?',
    comments: 'Comments?',
    notifyMe: 'Please have my sales rep notify me.',
  },
  checkout: {
    title: 'Checkout',
    purchaseOrder: 'Purchase Order',
    enterPO: 'Enter Purchase Order',
    cartSummary: 'Cart Summary',
    billToAdd: 'Bill To Address',
    shipToAdd: 'Ship To Address',
    deliveryOptions: 'Delivery Options',
    payment: 'Payment',
    orderSummary: 'Order Summary',
    distinctItems: 'Distinct Items',
    units: 'Units',
    futureDate: 'Ship On Future Date',
    signatureDelivery: 'Require Signature For Delivery*',
    notifyClient: 'Notify Client / Addressee of Shipment',
    carrierNotifier: 'Powered by Carrier NotifierTM',
    off: 'Off',
    otherMethods: 'Other Payment Methods',
    payWith: 'Pay with',
    products: 'Products',
    promotional: 'Promotional Savings',
    shippingAndHandling: 'Shipping & Handling',
    estTaxes: 'Taxes',
    surcharge: 'Surcharge',
    orderTotal: 'Order Total:',
    estimated: 'Est.',
    expiresOn: 'Expires On',
    billToInvoice: 'Bill to Invoice',
    addNewCard:
      'Add a credit or debit card - {{ currentSite }} accepts all major credit cards.',
    on: 'On',
    hazardousFee: 'Hazardous Fee',
    rate: 'rate',
    placeOrder: 'PLACE YOUR ORDER',
    confirmEmail: 'Order confirmation and updates will be emailed to',
    cartId: 'Cart #',
    selectCardError: 'Error! Please select payment method',
    selectCardExpire: 'Error! Your selected payment method is expired',
    selectDeliveryError: 'Error! Please select delivery options',
    default: 'DEFAULT',
    curbside: 'Curbside',
    showHazardousMsg:'There are Hazardous / ORMD items on this order that cannot be shipped to this address. Please select another address or add a new address.',
    overWeightMsg:'Orders over 75 lbs. may incur additional freight charges, a customer service representative will contact you.',
    overDropshipMsg:'There are items on this order that ship from the manufacturer. Customer service will contact you with a quote.',
    manufacturerRebates:'Manufacturer Rebates'
  },
  cart: {
    creditsApplied: 'MFG Rebates (Applied)',
    orderReturnInfo:'Return Information',
    returnStatus:'RETURN STATUS',
    return:'RETURN',
    startShopping:'Start Shopping',
    returnTotals:'Return Totals',
    returnTotal:'RETURN TOTAL',
    returnPlaced:'RETURN PLACED',
    shippingFrom: 'Shipping From',
    approve:'Approve Orders',
    myMainAccount: 'My Account',
    viewLastOrder:'View Last Order From: ',
    createShip:'Create Patient / Client Drop Ship',
    multipleCartItems: 'Cart ( {{ totalCount }} Items ) ',
    singleCartItems: 'Cart ( {{ totalCount }} Item ) ',
    multipleReorderItems: 'Reorder ( {{ totalCount }} Items ) ',
    singleReorderItems: 'Reorder ( {{ totalCount }} Item ) ',
    cart: 'Cart',
    billPay:'Online Bill Pay',
    onlineBillPayHeader:'Your wallet for payment of purchases',
    shipToError:'Please select ShipTo Address',
    selectWarehouse: 'Select Warehouse',
    whatDoes: 'What does this do?',
    shippingTo: 'Shipping To',
    clear: 'Clear',
    edit: 'Edit',
    access:'Access',
    estimateDeliveryDate: 'Estimated delivery date ',
    updateCart: 'Update Cart',
    clearCart: 'Clear Cart',
    item: 'ITEM#',
    mfgItem: 'MFG ITEM #',
    hcpcs: 'HCPCS',
    qty: 'Qty',
    qtyEarned: 'Qty Earned',
    update: 'Update',
    inventory: 'Inventory',
    warning: 'Warning',
    clearCartWarningMsg:
      'You are about to clear the cart. This action can not be undone.',
    clearShipToMsg: 'Are you sure you want to delete this account? The account will be removed, but associated orders will remain in your order history.',
    shippingFromWarehouse: 'Shipping From Warehouse',
    shippingWarehouseMsg:
      'When selecting one of the warehouses in the drop down this is the warehouse the entire order will be shipped from.  Typically you have a primary warehouse assigned but when changing this to the other warehouses offered the entire order will now ship from the selected warehouse',
    closeBtn: 'Close',
    remove: 'Remove',
    price: 'Price',
    updateSuccess: 'Cart Updated Successfully',
    updateFailure: 'Error while updating Cart',
    updateWarehouseSuccess: 'Warehouse Updated Successfully',
    updateWarehouseFailure: 'Error while updating Warehouse',
    continue: 'Continue',
    cancel: 'Cancel',
    eta: 'ETA ',
    linePromo: {
      promo: 'Promo',
      promoApplied: 'Promo Applied',
      applyPromo: 'APPLY PROMO',
      remove: 'REMOVE',
      bogo:'BOGO',
      add: 'Add',
      moreToCart: 'More to Cart',
      promoAvailable: 'Promo Available!',
    },
    orderPromo: {
      promosAvailable: 'promos available',
      promotions: 'promotions',
      view: 'View',
      edit: 'Edit',
      selectedPromo: 'Selected Promotion',
      selectedPromos: 'Selected Promotions',
      promotion: 'promotion',
      youAreLeveraging: 'You are leveraging',
      youHave: 'You have',
      promoAvailable: 'promo avaliable',
    },
    freeFreight: {
      firstLine: 'You are selecting',
      ifYou: ' If you',
      secondLine: 'continue with this selection you will no',
      thirdLine: 'longer receive free shipping and you will be',
      charged: 'charged',
      for: 'for',
      notice: 'Notice',
      spend: 'SPEND',
      more: 'more to earn',
      free: 'FREE SHIPPING',
      freight: 'Free Shipping',
      shippingAddressChange: 'By changing your delivery address to a location that does not support Ground Shipping, you will no longer qualify for the Free Shipping promotion.',
      earned: 'You Have Earned FREE SHIPPING, Click in the Available Promotions Area to Apply.',
      freeText: 'FREE'
    },
    summary: 'Cart Summary',
    products: 'Products',
    promotional: 'Promotional Savings',
    viewPromos: 'view promos',
    hazardous: 'Hazardous Fee',
    estShipping: 'Shipping & Handling',
    estimated: 'Est.',
    estTaxes: 'Taxes',
    surcharge: 'Surcharge',
    estTotal: 'Order Total:',
    secureCheckout: 'START SECURE CHECKOUT',
    goBack: 'Continue Shopping',
    agreement: 'By continuing to Checkout, you are agreeing to our ',
    termsOfUse: 'Terms of Use , ',
    conditionOfsale: 'Conditions of sale',
    and: ' and ',
    privacy: 'Privacy Policy',
    pending: 'Pending',
    estWeight: 'Est. Shipping Weight: ',
    fee: 'Fees',
    shippingFee: 'Shipping Fees',
    shippingFeeDesc:
      'All shipping charges include handling, order processing, item selection, packaging, transportation of items, plus additional delivery charges (for large or bulky items, delivery to remote locations, and/or rush delivery). Delivery to Alaska, Hawaii and U.S. Territories will incure additional shipping fees.',
    taxes: 'Do You Charge Taxes?',
    taxesShortDesc:
      'Taxes are calculated for each shipping address total based on the state to which the item(s) is shipped.',
    taxesDesc:
      'Where and when applicable, we collect sales tax on shipping and handling. This amount will be included in your Total Tax at Checkout.',
    addShipTo: 'Add Ship To',
    promotionalItems: 'Promotional Items',
    lbs: 'lbs',
    mixMatch: {
      applyPromo: 'APPLY PROMO',
      youAreViewingPromo: 'You are viewing Promotion',
      backToPromoList: 'Back to Promotion List',
      youAreEligibleFor: 'You are eligible for',
      setOffer: 'set of offer',
      setsOffer: 'sets of offers',
      offer: 'Offer',
      selected: 'Selected',
      earned: 'Earned',
      selectUpto: 'Select up to',
      unitsFromBelowProducts: 'units from the products below',
      sku: 'SKU',
      qty: 'QTY',
      close: 'Close',
      nextStep: 'Next Step',
      back: 'Back',
      addFreeItemsToYourOrder: 'Add Free Items To Your Order',
      For: "For SKU",
      promoAlert: "you have requested more than is allowed",
    },
    promoSlider: {
      promoAndRebate: 'Promotions & Rebates',
      youHave: 'You have',
      promoToSelectFrom: 'promo to select from',
      promosToSelectFrom: 'promos to select from',
      backToCart: 'Back to Cart',
      applyPromo: 'Apply Promotion',
      cancelPromo: 'Cancel Promo',
      editPromo: 'Edit Selection',
      savingsApplied: 'Savings Applied',
    },
    creditModal: {
      headline: 'Manufacturer Rebates',
      cartHeadline: 'Cart Value & Available Rebates',
      brandsEligible: 'Brands Eligible',
      valueInCart: 'Value in Cart',
      creditAvailable: 'Rebate Available',
      creditApplied: 'Rebate Applied',
      totalCredit: 'Total Rebate Applied at Checkout',
      hintText: 'Hint: You have other brand credits.',
      hintSubText: 'Don’t forget to take advantage of',
      learnMore: 'Learn More',
      creditAutoApplied: 'Why are rebates auto applied?'
    },
    orderCredit: {
      heading: 'Rebates',
      view: 'View',
      applied: 'Applied',
    },
  },
  quickOrder: {
    boxout: 'Boxout',
    orderTool: 'Order Tools',
    title: 'Quick Order',
    subTitle:
      'Fast & efficient ordering by entering items by fields or flat file',
    singleLineTitle: 'Add to Cart Line by Line',
    singleLineSubTitle: 'Enter by {{ currentSite }} Item & Qty Needed',
    inputLabel: 'Item #',
    qtyLable: 'QTY',
    addBtn: '+ Add Fields',
    addToCartBtn: 'Add to Cart',
    addToFavBtn: '+ Add to Favorite List',
    bulkTitle: 'Copy & Paste Items & Qty',
    bulkSubTitle: `Copy and paste an item number and quantity separated by a comma. Make
    sure each new item number and quantity is on a separate line and in the following format:`,
    advBtn: 'Advanced Instructions',
    errorMsg: 'Error!  Your file format is invalid Please adjust',
    qtyError: 'Please enter a quantity',
    itemError: 'Please enter a item',
    notNumber: 'Please enter a digit',
    successMsg: 'Cart is added successfully',
    errorMsgBulk: 'No item is added in cart',
    formError: 'Please enter values.',
  },
  product: {
    viewSubstitutes: 'View Substitutes',
    productDetail: {
      viewMoreImages: 'Click here to view more images',
      productGroup: 'Product Group:',
      hcpcs: 'HCPCS: ',
      mfg: 'Mfg SKU: ',
      sku: '{{siteName}} SKU: ',
      viewMore: ' View More Details',
      each: '/ each',
      noVariantCartErrorMsg: 'Select a specific product to add to cart',
      noVariantFavErrorMsg:
        'Select a specific product to add to favorites list',
      loginToSeePrice: 'Login To See Price',
      select: 'Select',
      save: 'SAVE',
      was: 'Was',
      loginToViewPrice: 'Login To View: Price',
      accessYourPrice: 'please login to access your price',
      estimatedDeliveryate: 'Estimated delivery date',
      shopAllBrands: 'Shop all',
      selectProduct: 'Select a product to see final price and availability',
      warning65: 'WARNING ',
      viewAll: 'View all options >',
      createAccount: 'You must create an account or log in to see pricing',
      pricevisible: 'Pricing is visible once you login',
      californiaProposition: '| California Proposition 65',
      millikenSku: 'Milliken Medical SKU: ',
    },
    productSummary: {
      aboutItem: 'About This Item',
      details: 'Details',
      specifications: 'Specifications',
      ingredients: 'Ingredients',
      notContain: 'Does Not Contain',
      supplementFacts: 'Supplement Facts',
      servingSize: 'Serving Size:',
      perContainer: 'Servings Per Container:',
      perServing: 'Amount Per Serving',
      dailyValue: '% Daily Value',
      directions: 'Directions',
      warranty: 'Warranty',
      generalInfo: ' General Info',
      manuals: 'Manuals',
      serialNumber: 'Serial Number',
      download: 'Download',
      checkProduct: '* Please check back regularly',
      featureSpecs: 'Features & Specs',
      materialData: 'Material Data Safety Sheets',
    },
  },
  account: {
    cancel: 'Cancel',
    saveChanges: 'Save Changes',
    search: 'Search',
    //sign In Security component
    signInSecurityForm: {
      title: 'Sign-In & Security',
      usedId: 'userid:',
      formButtonLabel: ' Save Changes',
      errorAlertMsg: 'Error! Please fix the fields identified that have errors',
      successAlertMsg: 'Done! The information has been updated',
      successAltText: 'Success Green tick box',

      updateUserPassword: {
        title: 'Update user ID Password',
        passwordReenterAlert:
          'Your password is good please reenter in the field below',
        passwordMatched: 'Your password Matches!',
        passwordReady: 'Your password is ready to create!',
        passwordMustMatchTitle: 'Password must match:',
        passwordMustMatchSubtitle: 'Not yet',

        currentPassword: {
          label: 'Current Password *',
          currentPasswordRequired: 'Please provide the current password',
        },
        newPassword: {
          label: 'New Password *',
          newPasswordRequired: 'Please provide a new password',
          creteria: {
            label: 'Must contain:',
            char: '8-20 Characters',
            lowerChar: 'At least 1 lower case letter',
            upperChar: 'At least 1 upper case letter',
          },
          optionalCreteria: {
            label: 'And 1 of the following:',
            oneNumber: '1 Number',
            specialChar: 'Special Characters',
          },
        },
        reEnteredNewPassword: {
          label: 'Reenter New Password',
          reEnteredNewPasswordRequired: 'Please confirm your password',
        },
      },
    },
    payment: {
      buttonName: {
        addPaymentType: 'Add New Payment Method',
        paymentType: 'Payment Types',
        addPayment: '+Payment',
        paymentOfPurchases: ' Your wallet for payment of purchases',
        editCard: 'Edit Card',
        defaultPayment: 'Default Payment',
        contactUs: 'Contact Us',
        specialOrdersCard: 'Special Orders Card',
        creationDate: 'Creation Date',
        creditCard: 'Credit card ending in',
        expirationDate: 'Expiration Date',
      },
      addEditCard: {
        addTitle: 'Add Credit or Debit Card',
        editTitle: 'Edit Credit or Debit Card',
        cardInfoTitle: 'Card Information',
        nickname: 'Card Nickname',
        nameOnCard: 'Name on Card',
        nameOnCardRequired: 'Please enter the name on card',
        cardNumber: 'Card Number',
        cardNumberRequired: 'Please enter a valid card number',
        expDateTitle: 'Expiration Date & CVV',
        monthTitle: 'MM',
        yearTitle: 'YYYY',
        cvv: 'CVV',
        issueNumberPattern:'Please enter a valid CVV',
        issueNumberRequired:'Please enter a CVV',
        expiryRequired: 'Please select an expiry month/year of your card',
        validExpiryRequired: 'Please enter a valid expiration date',
        billingAddressTitle: 'Current Address',
        billingAddressImpText: 'Important! ',
        billingAddressImpWarning: 'To ensure your card can be added to your account, please double-check that the address above matches exactly to what is listed on your credit card statement. If it does not match, you will not be able to add the card.',
        addAddress: 'Add Address',
        defaultCardTitle: 'Set as Default Card',
        addPaymentBtn: 'Save Changes',
        editPaymentBtn: 'Update Information',
        removeCardBtn: 'Remove Card',
        cardTxt: 'Card',
        cardNumberTokenInvalid: 'Card Processing Error: Invalid Token',
        cardTypeInvalid: 'Please enter a valid card number',
        addNewAddressTxt: 'Add New Address',
      },
    },
    accountLeftPanel: {
      userAccountInfo: {
        account: 'ACCOUNT',
        business: 'BUSINESS NAME',
        hi: 'Hi, ',
        loyaltyYear: 'Loyal Account Since  ',
      },
      buttonText: {
        manageButton: 'Manage',
        switchButton: 'Switch',
        manageButtonCaps: 'MANAGE',
      },
    },
    accountUserInfo: {
      representative: 'Your Representative',
      manager: 'National Account Manager',
      contact: 'Contact ',
      contactUs: 'Contact Us',
      customerService: 'Customer Service',
    },
    orderHistoryList: {
      noData: 'You have no order history available',
    },
    ordersHistory: {
      orderHistory: 'Order History',
      orderHistoryDescription:
        'Your all orders can be viewed and can be filtered based on the inputs',
      noOfHistory: 'Your 5 Most Recent Orders',
      viewButton: 'View All Order History',
      viewButtonMobile: 'View All',
      orderedVia: 'ORDERED via ',
      total: 'TOTAL',
      billToAcct: 'BILL TO ACCT: ',
      order: 'ORDER: ',
      shipTo: 'SHIP TO: ',
      po: 'PO: ',
      seeDetails: 'See Details',
      orderNumber: 'Order or PO #',
      accountName: 'Account Name',
      orderStatus: 'Order Status',
      account: 'Account',
      dateRange: 'Date Range',
      startDate: 'Start Date',
      endDate: 'End Date',
      search: 'Search',
      cancel: 'Cancel',
      advancedSearch: 'Advanced Search',
      allAccountsText: 'All Accounts',
      allStatusText: 'All Status',
      dateRangeHeader: 'Date Range',
      startDateHeader: 'Start Date',
      endDateHeader: 'End Date',
      dateRangeError: 'Please provide a valid date range',
      records: 'Records',
      attendant: 'Attn: ',
      searchForOrder: 'Search For Order',
      estimated: 'Est.'
    },

    //create business account component
    createAccount: {
      clientShippingTitle: 'What type of client are you shipping to?',
      createClientBusinessShipToAddressTitle:
        'Create Client / Business Ship to Address',
      title: 'Create Business Account',
      businessInfo: 'Business Information',
      clientInfo: 'Client Information',
      clientTypeTitle: 'Select Type *',
      clientTypeRequired: 'Please select the client type',
      firstNameTitle: 'First Name *',
      firstNameRequired: 'Please enter your first name',
      lastNameTitle: 'Last Name *',
      lastNameRequired: 'Please enter your last name',
      businessNameTitle: 'Business Name *',
      businessNameRequired: 'Please enter your business name',

      titleRequired: 'Please enter title',
      certifications: 'Certifications',
      businessNumber: '',
      attnTitle: 'Attn',
      phoneNumber: 'Phone Number *',
      phoneNumberRequired: 'Please enter your phone number',
      phoneNumberValid: 'Please enter a valid phone number',
      phoneNumberOrderInfo:
        "We'll contact you in case anything comes up with your account or order.",
      mobileNumber: 'Mobile Number',
      mobileNumberValid: 'Please enter a valid mobile number',
      deliverNotesTitle: 'Deliver Notes',
      cancelA: 'Cancel',
      createA: 'Create Account',
      createShipTo: 'Create Ship To',
      defaultShippingToTitle: 'Default Shipping To',
      defaultBillToTitle: 'Default Bill To',
      saveA: 'Save Changes',
      transactionalEmailTitle:
        'Send transactional emails via carrier notifier when a drop shipment ships.',
      createNewUserTitle: 'Create New User Account',
      createNewUserDescription:
        'This user will be created and will have access to account',
      userNameTitle: 'User Name',
      newUserPhoneRequired: 'Please enter your Business Number (Primary)',
      securityCredTitle: 'Security Credentials',
      securityCredDescription:
        'Select the security and access this user should have when logging in.',
      newUserSelectRole: 'Select Role',
      createUserCredButton: 'Create User Credentials',
      addAddress: 'ADD',
      clientPhoneNumber: 'Phone Number',
      businessEmail: 'Business Email Address', 
    },
    manageBusinessAcct: {
      manageAcct: 'Manage Account',
      businessInfo: 'BUSINESS INFORMATION',
      acctType: 'Account Type',
      acctNum: 'Account Number',
      businessType: 'Business Type',
      editInfo: 'Edit Information',
      phoneNo: 'PHONE NUMBER',
      phoneNumber: 'Phone Number',
      mobileNumber: 'Mobile Number',
      recieveSms: 'This mobile number is enrolled to recieve SMS Text Messages',
      licensing: 'LICENSING & TAX INFORMATION',
      taxId: 'Tax ID / EIN #',
      medicalLicense: 'Medical License #',
      expires: '|  Expires',
      acctAdd: 'ACCOUNT ADDRESSES',
      acctUsers: 'ACCOUNT USERS',
      createUser: 'Create User',
      editBusinessHead: 'Manage Business Info & Settings',
      editBusinessName: 'Business Name',
      editBusinessType: 'Business Type *',
      editPhoneHead: 'Phone Numbers',
      editOfficeNo: 'Office * ',
      editMobile: 'Mobile',
      sendTransactional:
        'Send transactional emails via carrier notifier when a drop shipment ships.',
      editTaxIdLicenceHead: 'Tax & Medical License',
      editTaxId: 'Tax ID / EIN #',
      editLicenceHead: 'Licensing & Expiration Date',
      editMedicalLicense: 'Medical License',
      editExpirationDate: 'Expiration Date',
      requiredOfficeNo: 'Please provide a office phone number',
      editAccountOwner: 'Account Owner Name',
      editFirstName: 'First Name *',
      editLastName: 'Last Name *',
      editMobileEnrolled:
        'Send transactional emails via carrier notifier when a drop shipment ships.',
      userAcctId: 'Acct ID: ',
      userSubAcctId: 'Sub Acct ID: ',
      userRole: 'Role: ',
      userLastLogin: 'LAST LOGIN: ',

      errorRequiredBusinessType: 'Please select the business type',
      errorRequiredFirstName: 'Please enter your first name',
      errorMaxName: 'Maximum 20 characters allowed',
      errorRequiredLastName: 'Please enter your last name',
      errorRequiredPhoneNumber: 'Please enter your phone number',
      errorValidPhone: 'Please enter a valid phone number',
      errorValidMobile: 'Please enter a valid mobile number',
      errorRequiredTaxId: 'Please enter your tax id',
      errorMaxTaxId: 'Maximum 30 characters allowed',
      errorMaxLicense: 'Maximum 128 characters allowed',
      errorRequiredMedicalLicense: 'Please enter your medical license',
      errorRequiredExpirationDate:
        'Please enter medical license expiration date',
      infoUpdateSuccess: 'Done!  The information has been updated',
      infoUpdateError:
        'Error!  The information was not updated. Please try again',
      primaryAccount: 'Primary Business Account',
      // editTitle: 'Title',
      // editMM: 'MM *',
      // editDD: 'DD *',
      // editYY: 'YY *'
      editPhoneNo:'Phone *'
    },
    businessAccounts: {
      primary: 'PRIMARY BUSINESS ACCOUNT',
      businessAccount: 'Business Account',
      mainAccountSubHeader:'Your Main account',
      addShipTo: 'Add Ship To',
      orderHistory: 'Order History',
      viewAddresses: 'View Addresses',
      hideAddresses: 'Hide Addresses',
      manageUsers: 'Manage Users',
      manageAcct: 'Manage Acct',
      acct: 'Acct',
      created: 'Created',
      id: 'ID',
      subAccountTo: 'Sub Account To',
      viewOrderHistory: 'View Order History',
      createShipment: 'Create Shipment',
      primaryShipTo: 'Primary Ship To',
      primaryBillTo: 'Primary Bill To',
      editAddress: 'EDIT ADDRESS',
      businessAccounts: 'My Business Accounts',
      mainAccount:'My Account',
      businessOwns: 'Accounts your business owns & operates',
      infoUpdateError:
        'Error!  The information was not updated. Please try again',
      infoUpdateSuccess: 'Done!  The information has been updated',
    },
    userId: {
      userId: 'User ID & Preferences',
      userIdLogin: 'UserID Login',
      userIdHeading: 'USER ID',
      edit: 'Edit',
      password: 'Password',
      changePassword: 'Change Password',
      notificationHeading: 'Transaction Email Notifications',
    },
    emailModal: {
      heading: 'Primary Email/ Username',
      subHeading: 'CURRENT EMAIL ADDRESS',
      emailLabel: 'Email *',
      emailRequiredError: 'Please provide the new email id',
      emailInvalidError: 'Please enter a valid email address',
      emailAlreadyExists: 'An account already exists for this email address',
      reEnterEmailLabel: 'Reenter Email *',
      reEnterEmailRequiredError: 'Please confirm your email',
      reEnterMisMatchError: 'Email address entered do not match',
      toolTip: 'IF YOU CHOOSE TO CHANGE YOUR EMAIL YOU',
      toolTip2: 'MUST USE YOUR PASSWORD TO SET THIS.',
      passwordLabel: 'Password',
      passwordRequired: 'Please provide your password',
      validPassword: 'Please enter your valid current password',
      saveChanges: 'Save Changes',
      requiredEmailError: 'Please enter an email address',
    },
    clientAndAddress: {
      clientAndAddressTitle: 'My Clients & Addresses',
      clientAndAddressSubTitle: 'Clients you drop ship products to',
      clientDropShipAccount: 'Client Drop Ship Account',
      ribbenHeading: 'Client Drop Ship Account',
      searchPlaceholder: 'Search for Client, Addresses',
      removeClientShipTo: 'Remove Client'
    },
    manageClientAccount: {
      manageAcct: 'Manage Client Account',
      acctTxt: 'Account',
      clientInfo: 'CLIENT INFORMATION',
      acctType: 'Account Type',
      phoneNumberSecTitle: 'PHONE NUMBER',
      phoneNumber: 'Phone Number',
      mobileNumber: 'Mobile Number',
      editInfoBtnTxt: 'Edit Information',
      acctAddressTxt: 'ACCOUNT ADDRESSES',
      editPhoneHead: 'Phone Numbers',
      editOfficeNo: 'Office * ',
      editMobile: 'Mobile',
      errorRequiredPhoneNumber: 'Please enter your phone number',
      errorValidPhone: 'Please enter a valid phone number',
      errorValidMobile: 'Please enter a valid mobile number',
      editMobileEnrolled:
        'Send transactional emails via carrier notifier when a drop shipment ships.',
        editPhoneNo:'Phone'
    },
    searchResults: {
      header: 'Search results for ',
      totalItems: 'total items',
      allFilters: 'All Filters',
      compare: 'Compare 3',
      viewBy: 'View By',
      featured: 'featured',
      clearFilters: 'Clear Filters',
      selectedFilters: 'Selected Filters',
      filter: 'Filters',
      showMore: 'Show More',
      showLess: 'Show Less',
      backTotop: 'Back to top',
      sortBy: 'Sort By',
      learnMore: 'Learn More',
      shop: 'Shop',
      experience: 'Experience'
    },
    productTile: {
      compare: 'Compare',
      item: 'ITEM: ',
      lastPurchased: 'Last Purchased: ',
      available: 'Available: ',
      inv: ' Inv: ',
      inStock: 'In Stock',
      readyToShip: ' | Ready to Ship',
      outOfStock: 'Out of Stock',
      pipe: ' | ',
      backOrdered: 'MFG Back-Ordered',
      discontinued: 'Discontinued',
      specialOrder: 'Special Order',
      promo: 'Promo',
      addtoCart: 'Add to Cart',
      maxQuantity: 'MAX QUANTITY REACHED',
      chooseOptions: 'Choose Options',
      eta: 'ETA ',
      keepMeUpdated: 'Notify Me When In Stock',
      callMe: 'Have My Rep Call Me',
      viewDetails: 'View Product Details',
      viewHoverDetail: 'View Details',
      requestConsultation: 'Request Free Consultation',
      substitution: 'This is no longer available Click Here for Substitutes',
      checkOutOptions: 'Although MFG Back-Order Check Out These Options',
      orderInMultiples: 'ORDER IN MULTIPLES OF ',
      siteSku: '{{ baseSiteName }} SKU: ',
      mfgSku: 'Mfg SKU: ',
      hcpcs: 'HCPCS: ',
      variable: 'Variable: ',
      inventoryLevel: 'Inventory Levels',
      tx: 'TX: ',
      oh: 'OH: ',
      wa: 'WA: ',
      error: 'Error: ',
      cart: '+ Cart',
      calculating: 'Calculating...',
      proffesionalPrice: 'processing your professional price',
      quantityError: 'PLEASE ENTER A QTY',
      multipleError: 'PLEASE ORDER IN MULTIPLES OF ',
      colon: ': ',
      add: 'Add ',
      count: ' Count',
      toReceieve: 'more to receive ',
      exclusionItem: 'Not eligible for Promotions',
      orderExclusionItem: 'Not eligible for order level Promotion',
      save: 'SAVE',
      was: 'Was'
    },
    registerForm: {
      attn: 'ATTN',
      headerText: 'Register & Create Account',
      businessNameTitle: 'Business Name *',
      businessOrPracticeType: 'Business / Practice Type *',
      businessOrPracticeTypeRequired: 'Please enter business/practice Type',
      title: 'Title *',
      titleRequired: 'Please enter title',
      certifications: 'Certifications',
      businessNumber: 'Business Number (Primary) *',
      businessNumberRequired: 'Please enter your Business Number (Primary)',
      emailRequired: 'Please enter your Email Address',
      emailNotMatch: 'Email addresses entered do not match',
      businessExt: 'Business Ext',
      cellPhone: 'Cell Phone',
      emailAddress: 'Email Address *',
      reEnterEmailAddress: 'Reenter Email Address *',
      password: 'Password *',
      reEnterPassword: 'Reenter Password *',
      userInfo: 'User Information',
      minMaxError:
        'Please enter minimum 10 character and maximum 14 characters are allowed',
      maxTenError: 'Maximum 10 characters are allowed',
      userID: 'UserID',
      continue: 'Continue',
      yes: 'Yes',
      no: 'No',
      userAlreadyExists: 'This user already exists',
      userRequestedName: 'User Requested :',
      twoOptions: 'Two options',
      likeTodo: 'What would you like to do?',
      fewOptions: 'You do have a few options',
      contactSales: 'I would like to contact a sales representative',
      myUserId: 'This is my User ID',
      prevScreen: 'Go back to previous screen',
      loginNow: 'I want to login now',
      forgotAndReset: 'I forgot my password need to reset it',
      setUpnewAccount: 'I need to setup another account with this userID',
      anotherAccountRequest: 'Another account request form',
      contactSupport: 'Contact Customer Support',
      yourInfo: 'Your Information',
      thankYou: 'Thank you for contacting us',
      infoSubmit:
        'The information above will be submitted to customer service to notify them that you are looking for user creation support',
      accountSubmit:
        'The information above will be submitted to customer service to notify them that you are looking to create another business account',
      notes: 'Notes: ',
      supportCall: 'I would like support to call me so i can access my account',
      back: 'Back',
      submitRequest: 'Submit Request',
      closeScreen: 'Close Screen',
      recaptchaText:
        'Invisible reCAPTCHA by Google Privacy Policy and Terms of Use.',
      tel: 'Tel :',
      yourUserInfo: 'Your user Information',
      accountDetails: 'Account Details',
      accountCreated: 'Account Created',
      uploadMedical: 'Upload  Medical License or other Tax Documents',
      uploadSize:
        'Please upload any legal documents to allow us to properly setup your account.   (Limit to 256MB)',
      submitCondition:
        'By clicking Create Account, you agree to the Terms and Conditions and Terms of Sale.',
      salesTax: 'Sales & Use Tax Exemption (Optional) ',
      taxId: 'Tax ID  (Optional) ',
      businessLicense: 'Business / License ',
      browseDoc: 'Browse My Documents',
      businessAddress: 'Business Address',
      specialServices: 'Any other types of specialty services?',
      servicesOffered: 'Service Offered',
      whyBoxoutAccount: 'Why are you creating an account with Boxout',
      bussinessInfoText:
        'In order to have access professional pricing and to purchase certain healthcare products that require a prescription and / or are restricted to only healthcare providers, please submit your license number or state issued license number for you to operate as a healthcare provider.',
      tryAgain: 'Try Again',
      taxOrEIN: 'Tax / EIN # ',
      regdate: 'Expiration Date :',
      step3Title: ' we have created an account for ',
      userIdOrEmail: 'User ID / Email Address',
      cellNo: 'Cell Number',
      firstOrLast: 'First & Last Names',
      congratulations: 'Congratulations!',
      accountUpper: 'ACCOUNT',
      busName: 'BUSINESS NAME',
      userIDupper: 'USER ID',
      regError: 'Looks like there is an issue',
      sysError:
        'Due to a system error we are unable to process your request at this time.',
      try: 'Try Again',
      emailSubscribeDescription:'Sign me up for email notifications, promotions, & important marketing announcements'
    },
    itemPurchaseReport: {
      itemPurchaseActHeader: 'Items Purchase Activity Report',
      itemPurchaseActDesc:
        'Your items purchase activity reports can be viewed & downloaded',
      ebillHeader: 'Advanced eBill Report',
      ebillDesc: 'Your eBill reports can be viewed & downloaded',
      reportFormHeader: 'Which activity reports are needed?',
      dateRangeHeader: 'Date Range',
      startDateHeader: 'Start Date',
      endDateHeader: 'End Date',
      accountsHeader: 'Accounts (Location)',
      barndsHeader: 'Brands',
      invoiceHeader: 'Invoice, PO, Order#',
      itemHeader: 'Item #',
      dateRangeError: 'Please provide a valid date range',
      orText: 'Or',
      allText: 'All',
      naText: 'NA',
      modifySearch: 'Modify Search',
      downLoadCSV: 'Download CSV',
      reorder: 'Reorder',
      date: 'Date - ',
      account: ', Account - ',
      dateRange: 'Date Range - ',
      brands: ', Brands - ',
      Order: ', Order - ',
      Item: ', Item - ',
      thru: ' thru ',
      backorderHeader: 'Backorder Report',
      backorderDesc: 'Your Backorder reports can be viewed & downloaded',
    },
    itemPurchasetable: {
      queryHeader: 'Query',
      accountHeader: 'Account',
      OrderHeader: 'Order #',
      InvoiceHeader: 'Invoice',
      noData: 'No Records Available',
      notSupported:
        'This page is not supported in mobile devices . Please use Tablet / Laptop / Desktop',
      orderDetails: 'Order Details',
      pod: 'Proof of Delivery',
      reorderPrd: 'Re-order Product',
    },
    favorites: {
      favoritesHeading: 'Favorite Lists',
      createNewList: 'Create new List',
      searchForList: 'Search for List',
      manage: 'Manage',
      addListToCart: 'Add List To Cart',
      createdBy: 'Created By:',
      createdOn: 'Created:',
      sku: 'SKU',
      quantity: 'QTY',
      brand: 'BRAND',
      productDescription: 'PRODUCT DESCRIPTION',
      remainingProducts: 'View {{x}} Additional Products',
      noLists: 'You have not setup any favorite lists',
      records: 'Records',
    },
    createFavoriteList: {
      createNewList: 'Create New List',
      nolistMessage: 'You have not created a list yet. Create one below.',
      listName: 'List Name',
      description: 'Description',
      errorAlertMsg: 'Favorite list already exists.',
      successAlertMsg: 'Favorite list created.',
      editList: 'Edit List',
      createList: 'Create New List',
      somethingWentWrongMsg: 'Sorry, something went wrong.',
      cancelButton: 'Cancel',
      enterListName: 'Please enter the List Name',
      noWishListsMsg: 'You have not created a list yet. Create one below.',
      advancedListFeatures: 'Advanced List Features',
      deleteList: 'Delete List',
      share: 'Share',
      lock: 'Lock',
      saveButton: 'Save',
      charactersRemaining: ' Characters Remaining',
      warningMsgOne: 'You are requesting to remove ',
      warningMsgTwo: ' item(s). Are you sure you want to do this.',
    },
    wishLists: {
      productAlreadyExistsInList: 'Product exists in the selected list.',
      somethingWentWrongMsg: 'Sorry, something went wrong.',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      createNewList: 'Create A New List',
      addToListHeader: 'Add to List',
      copyToListHeader: 'Copy to List',
      success: 'Success',
    },
    manageFavoriteList: {
      warningTitle: 'warning',
      editTitle: 'Edit',
      lastUpdateTitle: 'Last Update:',
      listCount: 'items in List',
      sortTitle: 'Sort',
      addSelectedToCart: '+ Add Selected To Cart',
      removeTitle: 'Remove |',
      copySelectedToTitle: 'Copy Selected To |',
      updateAllTitle: 'Update All',
      notes:
        'Note: {{siteName}} Product availability is real-time basis and adjusted continuously to ensure optimal customer satisfaction. The product you have created in this list will be reserved for you when you complete your order.',
      allSelectedItemUpdatedSuccessMsg:
        'Done!  Your have successfully Updated All Your Selected Items',
      allSelectedItemRemovedSuccessMsg:
        'Done!  Your have successfully Removed All Your Selected Items',
      copySelectedToFavoriteToExistingListSavedSuccessMsg:
        'Done! Your have successfully Added All Your Selected Items',
      atTheRate: ' @ ',
    },
    favoriteProduct: {
      priceTitle: 'Your Price',
      updateTitle: 'Update',
      inventoryStatusTitle: 'Inventory Status',
      inStockTitle: ' In Stock',
      outOfStockTitle: 'Out of Stock',
      discontinuedTitle: 'Discontinued',
      specialOrderTitle: 'Special Order',
      backOrderedTitle: 'Manufacturer Back-Ordered',
      skuTitle: 'SKU:',
      mfgTitle: '| MFG #:',
      perItemTitle: '/ ',
      quantityTitle: 'PLEASE ENTER A QTY',
      maxQuantityTitle: 'MAX QTY REACHED',
      quantityMultiplierTitle: 'ORDER IN MULTIPLES OF ',
      addedToMsg: 'Added to ',
      existsInMsg: 'Exists in ',
      failMsg: 'Fail',
      imageText: 'fav product',
      singleItemUpdatedSuccessMsg:
        'Done!  Your have successfully Updated Item: ',
      singleItemRemovedSuccessMsg:
        'Done!  Your have successfully Removed Item: ',
      selectTitle: 'Select',
    },
    order: {
      orderPlaced: 'Order Placed, Thank you!',
      orderPlacedSub:
        'Your order is being processed and a confirmation has been emailed to ',
      orderInfo: 'Order Information',
      orderNo: 'ORDER NUMBER',
      purchaseOrder: 'PURCHASE ORDER',
      clientPO: 'CLIENT  PO #',
      orderedOn: 'ORDERED ON',
      paymentMethod: 'PAYMENT METHOD',
      clientSalesOrder: 'CLIENT SALES ORDER #',
      orderedVia: 'ORDERED VIA:',
      sysOrderId: 'SYS ORDER ID:',
      orderedBy: 'ORDERED BY',
      orderStatus: 'ORDER STATUS',
      print: 'Print',
      email: 'Email',
      reOrderItems: 'Reorder Items',
      shipTo: 'SHIP TO',
      billTo: 'BILL TO',
      address: 'ADDRESS',
      shippingMethod: 'SHIPPING METHOD',
      futureShippingDate: 'FUTURE SHIPPING DATE',
      account: 'ACCOUNT',
      details: 'Details',
      item: 'ITEM#',
      hcpcs: 'HCPCS',
      mfg: 'MFG ITEM #',
      shipping: 'Shipping Enroute',
      price: 'Price / Pack',
      qty: 'QTY',
      ext: 'EXT PRICE',
      orderTotal: 'Order Totals',
      subTotal: 'Subtotal',
      items: 'Items',
      shippingCap: 'Shipping & Handling',
      taxes: 'Taxes',
      orderTotalFinal: 'ORDER TOTAL',
      startReturn: 'Start Return',
      proofOfDelivery: 'Proof of Delivery',
      orderAgain: 'Order Again',
      edit: 'EDIT',
      promotions: 'Promotions Applied',
      rejected: 'rejected',
      modifyCancel: 'modified cancelled',
      unapproved: 'unapproved',
      cancelled: 'cancelled',
      onHold: 'on hold',
      suspended: 'suspended',
      backordered: 'backordered',
      partiallyShipped: 'partially shipped',
      approved: 'approved',
      open: 'open',
      orderRecieved: 'order received',
      created: 'created',
      processing: 'processing',
      pendingApproval: 'pending approval',
      shippingStat: 'shipping',
      completed: 'completed',
      shipped: 'shipped',
      singleItemRemovedSuccessMsg:
        'Done!  Your have successfully Removed Item: ',
      selectTitle: 'Select',
      siteId: 'SITE ID:',
      lineOfBusiness: 'LINE OF BUSINESS',
      remove: 'Remove',
      addToOrder: '+ Add to Order',
      editInfo: 'Edit Order',
      orderApproval: 'Order Approval',
      modalheadingOne: 'This order will be approved and the user',
      modalHeadingTwo: ' will be notified',
      order: ' Order ',
      po: 'PO: ',
      orderDate: 'Order Date: ',
      anyNotes: 'Any Additional Notes?',
      notes: 'Notes',
      submitApproval: 'Submit Approval',
      modalheadingThree: 'This order will be rejected and the user',
      rejectOrder: 'Order Rejection',
      rejectClose: 'Reject & Close',
      rejectAllow: 'Reject & Allow Order To Be Resubmitted',
      quickOrderHeading: 'Add Additional Items To Your Order',
      invoice: 'Invoice',
      awaitingReturn: 'awaiting return',
      reject: 'Reject',
      approve: 'Approve',
      cancelOrder: 'Cancel Order',
      editPO: 'Edit PO',
      editPayment: 'Edit Payment',
      reviseOrCloseOrder: 'Revise or Close Order',
      orderRequiringApproval: 'Order Requiring Approval',
      purchaser: 'Purchaser',
      approver: 'Approver',
      admin: 'Admin',
      approvalHeading: 'Order has been approved',
      rejectHeading: 'Order has been rejected & can be Edited',
      rejectHeadingClosed: 'Order Rejected and Closed',
      editOrder: 'editorder',
      reqApproval: 'reqApproval',
      close: 'Close',
      resubmit: 'Resubmit',
      bill: 'bill',
      creditCard: 'credit card',
      fail: 'fail',
      success: 'success',
      paymentMethodUpdated: 'Payment Method Updated',
      somethingWentWrong: 'Something went wrong!',
      promotionalSavings: "Promotional Savings",
      hazardousFee: "Hazardous Fee",
      surcharge: "Surcharge",
      estimated: "Est.",
      curbside: "Curbside",
      approvedBy: 'APPROVED BY',
      rejectedBy: 'REJECTED BY',
      arriving: 'Arriving',
      noPodMessageOne:'There currently is no POD available for this order. Please contact ',
      noPodMessageTwo:' for further assistance.',
    },
    userAdmin: {
      pageHeading: 'User Admin',
      pageSubHeading: 'Those who can access your accounts',
      addUserButtonText: 'Create User',
      addUserButtonMobileText: 'User',
      removeUser: 'Remove User',
      searchBoxPlaceholder: 'UserID Search',
      parentTableHeaders: {
        userId: 'User ID',
        account: 'Account',
        role: 'Role',
        status: 'Status',
        manage: 'Manage',
      },
      childTableHeaders: {
        account: 'Account',
        role: 'Role',
        status: 'Status',
        manage: 'Manage',
      },
      userAccounts: 'USER ACCOUNT',
      userid: 'USERID',
      expand: 'Expand',
      collapse: 'Collapse',
      acct: 'Acct',
      manage: 'Manage',
      accessToTheseAccounts: 'ACCESS TO THESE ACCOUNTS',
      paginationOf: 'of',
      paginationRecords: 'Records',
      userRemovedMsg : 'Success! Selected users have been removed'
    },
    manageUser: {
      heading: 'Manage User',
      userRole: 'User Role Settings',
      selectRole: 'Select Role',
      purchasingRestrictions: 'Purchasing Restrictions',
      instructionOne:
        'Do you want to manage this users purchasing behavior? By turning this on you can set purchasing expenditure limits by time period and value, as well as control what products and categories can be purchased without approval',
      periodThreshold: 'User Spending Period Threshold',
      instructionTwo:
        'Control how much a user can spend during a time period for all purchases',
      approvedAmount: 'Total Approved Spend Amount:',
      threshholdTime: 'Threshold Time Period',
      spendingThreshold: 'Unique Order Spending Threshold',
      instructionThree: 'Select how much a user is allowed to spend per order',
      approvalThresholdLabel: 'Set the approval threshold for order: $',
      orderApprovedTitle: 'Require all orders to be approved',
      approvedPara:
        'Every order submitted by this user will require approval an approving role',
      categoryApproved: 'Require orders with these categories to be approved',
      categoryPara:
        'Any order with products that are in the following categories will require approval',
      userAccounbtAccess: 'User Account Access',
      userAccountPara:
        'Do you want to allow this user to access other accounts that are associated with your business. Doing this will allow this user to create orders and have access to your other accounts',
      cancel: 'Cancel',
      maskData: '***************',
      availableCategory: 'Available Categories',
      successMessage: 'Success! The user has been created',
    },
    categoryListing: {
      categoryListingTitle: 'Browse Categories',
      categorySpecificShopNow:'Shop Now'
    },
    downloadHistory: {
      downloadHeader: 'Download History',
      downloadSubHeader:
        'This is the place where you can find any file that you exported from item usage, ebill or POD export',
      docType: 'Document Type: ',
      user: 'USER: ',
      queryDetails: 'Query Details: ',
      recordCount: 'Record Count: ',
      fileId: 'File Id:',
      fileExpires: 'File Expires:',
      generated: ' Generated ',
      readyToDownload: 'Ready to Download',
      ebillReport: 'eBill Report',
      podBulkExport: 'POD Bulk Export',
      itemUsageReport: 'Item usage Report',
      download: 'Download',
      noData: 'No Reports downloaded',
      status: 'Status: ',
    },
    youMayLikeProducts: {
      cartRecommendationsTitle:'Recommendations',
      title: 'You May Also Like',
      save: 'SAVE',
      was: 'Was',
      loginTitle: 'Login To View Price',
      loginDescription: 'please login to access your price',
    },
    recentlyViewedProducts: {
      title: 'Products Recently Viewed',
      save: 'SAVE',
      was: 'Was',
      loginTitle: 'Login To View Price',
      loginDescription: 'please login to access your price',
    },
    rapidOrder: {
      rapidOrderText: 'Rapid Order',
      rapidOrderDescription: 'The fastest way to build an order',
      sku: 'SKU: ',
      mfgSku: 'MFG SKU: ',
      qty: 'QTY',
      rapidOrderSummary: 'Rapid Order Summary',
      skuCount: 'SKU Count ',
      totalUnits: 'Total Units ',
      totalValue: 'Total Value ',
      addSelectedToCart: 'Add Selected To Cart',
      clearSelection: 'Clear Selection',
      disclaimer:
        'Disclaimer: Your final price & promotions will be calculated in the cart and during checkout',
      yourPrice: 'YOUR PRICE',
      extPrice: 'EXT PRICE',
      itemsAddedToCart: 'Items Added To Cart',
      itemsAddedToCartDescription:
        'The products you have selected have been added to your cart and are ready for checkout',
      goToCart: 'Go to Cart',
      close: 'Close',
      noProducts: 'No products found for this category',
      orderInMultiples: 'ORDER IN MULTIPLES OF {{multiplier}}',
      totalSpend: 'TOTAL SPEND',
      plusSelectedToCart: '+ Selected To Cart',
      maxQuantityReached: 'MAX QUANTITY REACHED',
    },
    salesRepCallMe: {
      title: 'Have my sales rep call me',
      sku: 'SKU:',
      description: 'We will notify you at this contact info:',
      phoneNumber: 'Phone Number?',
      email: 'Email Address?',
      comments: 'Comments?',
      emailRequiredError:'Please enter an email address',
      emailInvalidError:'Please enter a valid email address'
    },
    otherShipToClientPatientAddresses: {
      back: 'Back',
      addNewShipToAdd: 'Add New Ship To Address',
      select: 'Select',
      edit: 'Edit',
    },
    shipToClientPatientAddresses: {
      title: 'Select Account',
      addNewClientPatientAddress: 'Add New Client / Patient',
      addNewClientPatientAddressForMesite: 'Add New Member / Guest',
      shipToThisAddress: 'Ship To This Address',
      addAddress: 'Address',
      otherAddresses: 'Other Addresses',
      records: 'Records',
      dropShip: 'DROP SHIP |',
      attn: 'Attn : ',
    },
    cartShipToModal: {
      title: 'Who are you shipping to?',
      clientOrPatient: 'Client / Patient',
      memberOrGuest: 'Member / Guest',
      myBusiness: 'My Business ',
    },
    cartShipToBusiness:{
      selectBusiness:"Select Business Account",
      addnewBusiness:"Add New Business Account",
      searchfor:"Search for Client / Patient",
      shipToaddress:"Ship To This Address",
      otherAddress:"Other Addresses",
      addNewShipTo:"Add New Ship To Address",
      select:"Select",
      edit:"Edit",
      address:"Address",
      primary:"PRIMARY",
      subAccount:"SUBACCOUNT",
      add:"ADD",
      addNewBillTo:"Add New Bill To Address",
    },
    po: {
      header: 'Edit PO for Order ',
      currentPO: 'Current PO',
      revisedPO: 'Revised PO',
      saveChanges: 'Save Changes',
      cancel: 'Cancel',
      equalMessage: 'Current PO and Revised PO can not be same',
      currentPOMessage: 'Current PO can not be empty',
      revisedPOMessage: 'Revised PO can not be empty',
    },
    editPayment: {
      header: 'Edit Payment Method for Order ',
      invoice: 'Invoice',
      creditCard: 'Credit Card',
      newCard: 'Setup New Card & Process Order',
      saveChanges: 'Save Changes',
      cancel: 'Cancel',
      setUpNewCard: 'Setup New Card',
      existingCard: 'existingCard',
      new: 'new'
    },

    breadCrumb: {
      mmsite: 'Milliken Medical',
      ptsite: 'MeyerPT',
      spasite: 'MeyerSPA',
      fwsite: 'Elivate Fitness',
      omsite: 'Obagi Medical',
      musite: 'Murad',
      chsite: 'MeyerDC',
      mesite: 'ME Supplies',
      boxout: 'Boxout Distribution',
      bxdportal: 'Boxout Distribution Solution',
      advancedeBill: 'advancedeBill',
      advancedeBillReport: 'advancedeBillReport',
      userIdAndPreferences: 'userIdAndPreferences',
      signInAndSecurity: 'signInAndSecurity',
      advancedEbill: 'Advanced eBill',
      advancedEbillReport: 'Advanced eBill Report',
      userIdPreferences: 'User ID & Preferences',
      signInSecurity: 'Sign-In & Security',
      ordersRequiringApproval: 'ordersRequiringApproval',
      ordersRequiringApprovalLabel: 'Orders Requiring Approval',
      orderDetail: 'orderDetail',
      orderDetailLabel: 'Order Details',
      orders: 'Orders',
      order: 'Order',
      products: 'Products',
      product: 'Product',
      inventory: 'Inventory',
      receiving: 'Receiving',
      user: 'User',
      settings: 'Settings',
      tranHist: " Transaction History",
      reports: "Reports",
      manufacturerSKU: "Manufacturer SKU Inventory"
    },
    supportEmailForBaseSiteName:{
      mmsite: 'millikenmedical',
      ptsite: 'meyerpt',
      spasite: 'meyerspa',
      fwsite: 'elivatefitness',
      omsite: 'obagiorder',
      musite: 'muradpro',
      chsite: 'meyerdc',
      boxout: 'boxouthealth',
      mesite: 'massageenvysupplies',
      salesSupportEmail: 'sales@',
      serviceSupportEmail:'service@',
      genericSupportEmail:'support@',
      supportEmailTwo: '.com',
    },
    siteNameForCard: {
      mmsite: 'Milliken Medical',
      ptsite: 'MeyerPT',
      spasite: 'MeyerSPA',
      fwsite: 'Elivate Fitness',
      omsite: 'Obagi Medical',
      musite: 'MuradPro',
      chsite: 'MeyerDC',
      mesite: 'Massage Envy Supplies',
      boxout: 'Boxout Distribution',
    },
    restrictedPDPWarningPage: {
      heading: 'Product Restricted',
      title: 'We’re sorry the product you requested could not be found.',
      descriptionOne:
        'If you feel this is an error please contact your sales rep or email us at ',
      descriptionTwo: ' Try going back to our ',
      descriptionThree: 'Homepage ',
      descriptionFour:
        "or click the 'live Help' button on the top right side of the page and we’ll hellp you find what you need.",
      descriptionFive:
        ' You can also use the search box below to directly find the items you’re looking for.',
    },
    manageFrequentlyPurchasedProductList: {
      title: 'Frequently Purchased Items',
      addSelectedToCart: '+ Add Selected To Cart',
      sortTitle: 'Sort',
      records: 'Records',
    },
    frequentlyPurchasedProduct: {
      priceTitle: 'Your Price',
      imageText: 'fav product',
      inventoryStatusTitle: 'Inventory Status',
      inStockTitle: ' In Stock',
      outOfStockTitle: 'Out of Stock',
      discontinuedTitle: 'Discontinued',
      specialOrderTitle: 'Special Order',
      backOrderedTitle: 'Manufacturer Back-Ordered',
      quantityTitle: 'PLEASE ENTER A QTY',
      maxQuantityTitle: 'MAX QTY REACHED',
      quantityMultiplierTitle: 'ORDER IN MULTIPLES OF ',
      skuTitle: 'SKU:',
      mfgTitle: '| MFG #:',
      perItemTitle: '/ ',
      imageTextFre: 'frequently product',
      selectTitle: 'Select',
      lastPurchased: 'Last Purchased:',
      addToCart: 'Add to Cart',
      itemUnapproved:'This item is no longer available',
      isBackOrderMsg: "This item is on manufacturer backorder and can't be purchased",
      isPurchaseExemptMsg: "This item is restricted and can't be purchased"
    },
    myAccountRep:{
      title:'Contact',
      yourName:'Your Name *',
      firstNameRequired:'Please enter your name',
      discussionPoint:'What do you want to discuss *',
      phoneNumber:'Phone Number *',
      mobileNumber:'Mobile Number',
      discussionDetails:'Details to discuss *',
      discussionDetailsRequired: 'Please enter details',
      cancel:'Cancel',
      submit:'Submit Request',
      phoneNumberRequired: 'Please enter your phone number',
      phoneNumberValid: 'Please enter a valid phone number',
      mobileNumberValid: 'Please enter a valid mobile number',
      accountRepDiscussRequired: 'Please select topic',
      emailLabel: 'Email *',
      emailRequiredError: 'Please enter your email address',
      emailInvalidError: 'Please enter a valid email address',
      accountRepDiscussTextAreaRequired: 'Please enter details'
     },

     // Rewards page constants
     rewards: {
      rewardsHeader: 'Manufacturer Rebates',
      rewardsDesc: 'Central reporting for valuable funds you can use during checkout',
      lifetimeEarned: 'Lifetime Earned',
      available: 'Available',
      earnedCredit: 'Earned credit',
      applied: 'Applied',
      useBy: 'Use By',
      totalEarned: 'Total Earned',
      order: 'Order',
      creditLeveraged: 'Credit Leveraged',
      viewOrderDetails: 'View Order Details',
      creditInformation: 'Credit Information',
      creditInfo: 'Credit Info',
      closeBtn: 'Close',
      totalEarnedLabel: 'Total Earned',
      lastUpdateLabel: 'Last update',
      availabelLabel: 'AVAILABLE',
      detailsLabel: 'Details',
      brandLabel: 'Brand',
      earnedLabel: 'Earned',
      totalAvaliableLabel: 'Total Available',
      expired: 'Expired',
      from: 'From',
      throughToday: 'through today',
      rebatesLabel : 'Manufacturer Rebates',
      errorMsg: 'Something went wrong. Please try again later',
      expiredCredits: 'Expired credits',
      creditOrderLabel: 'Credit',
      orderTotalApplied : 'Total Applied'
    }
  },
  bxdPortal:{
    authentication:{
      heading: 'Authentication',
      subHeading: 'The authentication code has been sent to ',
      demoEmail: 'jam**es@g*.com',
      message: 'Please open the email and enter the code into the field below',
      authLabel: 'Authentication Code',
      resendAuthCode: 'Resend Authentication Code',
      login: 'Log in',
      authPlaceholder: 'xxxxxx',
      authErrorMsg: 'Please enter the authentication code',
      authErrorMsg2: 'Error! The code You Entered is Incorrect',
      resendAuthHeading:'Resent Authentication',
      resendMFA_Message:'Please disregard any older emails and  enter the code into the field below from the latest email'
    },
    accountConfig: {
      heading: 'Account Settings | ',
      businessAcct: 'Your Business Accounts',
      masterAcct: "Corporate Master Customer Accounts",
      userAcct: "Users",
      vendor: "Vendor #",
      description: "Description",
      warehouse: "Warehouse Access",
      accountId: "Account ID",
      name: "Name",
      type: "Type",
      createdDate: "Created Date",
      active: "Active",
      users: "Users"
    },
    changePassword:{
      heading: 'Reset Password',
      updatePass: 'Update Password',
      saveChanges: 'Save Changes',
      specialChar:'Special Characters'
    },
    productDetails: {
      active: 'ACTIVE',
      lastUpdated: 'Last Updated',
      edit: 'Edit',
      prop65: 'Prop 65',
      hazardous: 'HARZARDOUS',
      ormd: 'ORMD',
      dotPermit: 'DOT PERMIT',
      productDetails: 'Product Details',
      general: 'General',
      boxoutSku: 'Boxout SKU', 
      manufacturerSku: 'Manufacturer SKU',
      upcSellable: 'UPC - Sellable',
      lotAndSerial: 'Lot & Serial',
      lotControlled: 'Lot Controlled',
      serialTrack: 'Serial Track',
      unitOfMeasure: 'Unit of Measure',
      each: 'Each',
      dimensionsWeightSellable: 'Dimensions & Weight - Sellable',
      length: 'Length',
      width: 'Width',
      height: 'Height',
      productWeight: 'Product Weight',
      classifications: 'Classifications',
      requiresPropWarning: 'Requires a Prop65 Warning',
      hazardClass: 'Hazard Class',
      rxItem: 'Rx Item',
      inventoryLocationTable: 'Inventory By Location',
      inventoryStatusTable: 'Inventory Status',
      lot: 'LOT',
      expiration: 'Expiration',
      available: 'Available',
      fortWorthTX2: 'Fort Worth TX2',
      ontarioCA2: 'Ontario CA2',
      incoming: 'Incoming',
      pendingPutaway: 'Pending Putaway',
      committed: 'Committed',
      quarantine: 'Quarantine',
      backorders: 'Backorders',
      lastRefresh: 'Last Refresh',
      est: 'EST'
    },
    product:{
      heading: 'Products',
      upcCell: 'UPC Sell',
      activeInventory: 'Total Active Inventory',
      totalUnits: 'Total Units',
      distinct: 'Distinct',
      skus: 'SKUS',
      mfgSkus: 'MFG SKUS',
      lots: 'Lots',
      productAlerts: 'Active Product Alerts',
      outOfStock: 'OUT OF STOCK',
      lastRefresh: 'Last Refresh',
      records:'Records'
    },
    productNav: {
      all: 'All',
      view: 'View',
      byLocation: 'By Location',
      byStatus: 'By Status',
      filter: 'Filter',
      filters: 'Filters',
      action: 'Action',
      expSelected: 'Export Selected',
      expAll: 'Export All Records',
      exp3Month: 'Export Orders (Last 3 Months)',
      scheduleExp: 'Schedule Export',
      addProduct: 'Add Product',
      sort: 'Sort',
      location: 'Location',
      searchPlaceholder: 'Search by SKU, Item Name etc',
      sku: 'SKU',
      today:'Today'
    },
    receiving: {
      heading: "Open Warehouse Receiving Orders",
      receiptHeading: "Warehouse Receipts",
      receiptDate: "Receipt Date",
      receivingOrder: "Receiving Order",
      boxoutSku: "Boxout SKU",
      manufacturer: "Manufacturer",
      product: "Product",
      orderQty: "Order Qty",
      receiptQty: "Receipt Qty",
      receipt: "Receipt",
      comments: "Comments",
      whse: "WHSE",
      noData: "No Records Found"
    },
    wroDetails:{
      summary: "Summary",
      heading: 'WAREHOUSE RECEIVING ORDER:',
      customerRefNo: "Customer Reference Number:",
      customerRefNo2: "Customer Ref #:",
      keyActions: 'KEY ACTIONS',
      downloadBox: 'Download Box Labels',
      addTracking: 'Add Tracking Numbers',
      addNotes: 'Add Notes',
      bxdWarehouse: 'BOXOUT WAREHOUSE RECEIVING',
      shippingFrom: 'SHIPPING FROM',
      shippingDetails: "SHIPPING DETAILS",
      shippingInventory: "SHIPPING DETAILS - INVENTORY",
      careerTracking: 'CARRIER TRACKING NUMBERS',
      notes: 'NOTES',
      addTrackingBtn: 'Add Tracking',
      billOfLading: "BILL OF LADING",
      browse: "Browse For Files",
      draft: "DRAFT",
      status: "STATUS:",
      submitted: "SUBMITTED",
      awaitingReceipt: "AWAITING RECEIPT",
      receiving: "RECEIVING",
      receivedComplete: "RECEIVED COMPLETE",
      canceled: "CANCELED",
      addCarrierTracking: "Add Carrier Tracking Number",
      carrier: "Carrier",
      trackingNumber: 'Tracking Number',
      add: "Add",
      cancel: "CANCEL",
      addNoteLabel: "Add Note - 200 Character limit",
      size: "Size",
      fileName: "File Name",
      message1: "To speed up unloading and put-away, please upload your Bill of Lading BOL if you have it onhand (optional, and this can be done later)",
      message2: "Approved file types are .PDF, .PNG, JPEG, JPEG - Max File size 3 MB",
      inventoryID: "Inventory ID",
      inventoryID2: "Inv ID",
      expirationDate: "Exp Date",
      skuMfg: "SKU - Mfg",
      qtySending: "Qty Sending",
      wro: "WRO:",
      shippingMethod: "Shipping Method:",
      estimateDate: "Estimated Arrival Date:",
      refNumber: "Reference Number:",
      noOfSku: "Number of SKUs:",
      phone: "Phone:",
      email: "Email:",
      errorMsgFileLimit: "Cannot Upload More than 5 files",
      errorMsgSizeLimit: " exceeds the maximum size of 3MB.",
      errorMsgSameFile: " has already been selected.",
    },
    inventory:{
      dateTime: 'Date/Time',
      lotExp: 'Lot Exp.',
      TransactionQty: 'Transaction Qty',
      uom: 'UOM',
      warehouse: 'Warehouse',
      transactionType: 'Transaction Type',
      sysOrigin: 'System of Origin',
      id: 'ID',
      omsPo: 'OMS PO#',
      sysOrder: 'SYS Order #',
      customerPO: 'Client PO',
      heading: 'Inventory by ',
      headingLotTitle: 'Inventory by Lot | On Hand',
      manufacturerSKU: 'Manufacturer Distinct SKU Inventory by ',
      select: 'Select',
      mfgSku: 'MFG SKU',
      lotView: 'Lot View',
      manufacture: 'Manufacturer',
      product: 'Product',
      ca: 'CA',
      tx: 'TX',
      oh: 'OH',
      fl: 'Fl',
      available: 'Available',
      incoming: 'Incoming',
      pending: 'Pending Putaway',
      commited: 'Commited',
      quarantine: 'Quarantine',
      backordered: 'Backordered',
      date: 'Date',
    },
    inventoryTransaction: {
      title: 'Inventory Transaction History',
      of: 'of',
      records: 'Records',
    },
    quarantineProduct: {
      title: 'Quarantined Inventory',
    },
    wro: {
      wroNotesEdit: "EDIT",
      vendor: 'Select Brand / Vendor *',
      title: 'Quarantine Inventory',
      modal: {
        title: 'Import Products via Flat File',
        desc: 'When you need to upload a  list of products we require the Boxout sku, qty your sending (sellable UOM). To ensure you upload correctly please download our ',
        descLink: 'Boxout Product Import Template',
        browseText: 'Please drop your completed file here or ',
        btnText: 'browse',
        uploadText: ' to upload.',
        hintText: 'Please use a .CSV'
      },
      warehouseRecievingOrder: "Warehouse receiving order",
      draft: "Draft",
      discard: "Discard",
      finishLater: "Finish Later",
      itemSending: "What items are you sending?",
      steps: "Step",
      of: "of",
      saveAndContinue: "Save & Continue",
      cancel: "Cancel",
      flatFileImport: "Import with a flat file",
      searchItem: "Search for your items *",
      searchItemPlacehoder: "Search by Name, MFG Item, SKU, UPC etc",
      addLot: "Add Lot #",
      vendorBrand: "Vendor / Brand",
      skus: "SKUs",
      edit: "Edit",
      whichWarehouse: "Which warehouse are you shipping to?",
      bxdWarehouse: "Boxout Warehouse *",
      systemOrderId: "Your Systems Order ID or Ref # (optional)",
      clientRefNo: "Client Reference Number",
      inventoryArriveToBXD: "How will your inventory arrive to Boxout",
      selectShippingType: "Select Shipping Type *",
      palletized: "Palletized",
      parcel: "Parcel",
      container: "Container",
      confirmShipping: "Confirm where you are shipping this inventory to",
      ensuareShipAddress: "Ensure your parcel shipment is addressed to:",
      shipFromMsg: "Shipment From Manufacturer?",
      shipMSGAdd: "You are shipping from this address",
      shipMsgCheck: "Yes, this order is coming from our manufacturing site",
      etaTitle: "To allow us to be prepared do you have an ETA for when this parcel might arrive?",
      etaDateRequired: "Estimated Arrival Date *",
      etaLabel: "Estimated Arrival Date *",
      removeAdd: "Remove Address",
      shippingTypeCond1: "To speed up unloading and put-away, please upload your Bill of Lading (BOL) if you have it on hand (optional, and this can be done later)",
      fileUploadType: "Approved file types are .PDF, .PNG, .JPG, .JPEG - Max File size 3 MB",
      browseForFile: "Browse For File",
      fileName: "File Name",
      size: "Size",
      finalStepTitle: "Final Step! Review and Submit your warehouse receiving order",
      shippingVia: "Shipping via: ",
      to: "to",
      etaDelieveryTitle: "Boxout Warehouse with an estimated delivery of ",
      shippingFrom: "Shipping From",
      refNo: "Client Reference Number: ",
      submitWRO: "Submit Warehouse Recieving Order",
      mfgModal: {
        addAndContactInfo: "Enter Address & Contact Information",
        firstName: "First Name *",
        lastName: "Last Name",
        businessName: "Business Name",
        attn: "Attn",
        address1: "Address 1 *",
        address2Opt: "Apt, Suite, etc. (Optional Address 2)",
        city: "City *",
        state: "State / Province *",
        zipCode: "ZIP Code / Postal Code *",
        country: "Country *",
        phNo: "Phone Number",
        contactStr: "We'll contact you in case anything comes up with your account or order.",
        email: "Contact Email"
      },
      palletizedSubtitle: "Within LTL or Container",
      parcelSubtitle: "Standard shipping via UPS, Fedex, USPS etc.",
      containerSubtitle: "Products have been loaded directly on floor",
      email: "Email",
      phoneNo: "Phone",
      changeVendorWarning: "Are you sure you want to change the Vendor / Manufacturer. If you change this you will loose any of your changes.",
      confirmDelete: {
        warning: 'Warning',
        message1: 'Are you sure you want to delete the file',
        message2: 'from this Warehouse Receiving Order?',
        infoMessage: 'Keep in mind once you delete this file you will be unable to access it.'
      },
      wroSubmit: {
        submitted: "SUBMITTED:",
        successWroTitle: "Success!  Your Receiving Order Has Created",
        failureWroTitle: "Error!  We are unable to process your Warehouse Receiving Order",
        wroId: "WRO ID:",
        createdBy: "Created by:",
        createdOn: "Created On:",
        rememberAppointment: "Remember to Schedule Your Recieving Appointment",
        impLabelingActivity: "Important Labeling Activity",
        subHeading1: "Please affix the attached labels to each",
        subHeading2: "that you are shipping",
        clickHere: "click here",
        anyQuestion: "If you have any questions",
        label: "Label",
        downloadNow: "DOWNLOAD NOW",
        impLabelingInstructions: "Important Labeling Instructions",
        impNextStep: "Important Next Steps",
        instructions1: "Print out the labels from link above.",
        instructions1_sub: "Click here to learn more.",
        instructions2: "Each Box should have a label affixed to the OUTSIDE of the box",
        instructions3: "Place labels on side or top of boxes. Never place on a seam that can be opened.",
        errInstructions1: "Typically these errors require assistance from the Boxout Distribution services team. Please contact your account representative or open a ",
        openATicket: "support ticket.",
        errInstructions2: "Even though your WRO failed to submit we saved a draft ",
        errInstructions3: "of the WRO. You can submit this again once the system is fixed.",
        noRetryWro: "No",
        resubmitWro: "Re-Submit",
        imp: "Important",
        confirmSubmit: "Are you sure you want to submit this Warehouse Receiving Order?",
        confirmaWroWarn: "Remember you can edit it up until the warehouse receiving order is set to arrival status. Tracking numbers should be entered in via the admin for this warehouse receiving order.",
        proccessing: "Processing",
        confirmaWroWarn1: "We are processing the warehouse receiving order and preparing your orders documentation.",
        errorStr: "Error Message:",
        draft: "Draft: ",
        reachOut: "Please reach out to the",
        teamAt: "team at",
        orEmail: "or email"
      },
      wroLimitReachedLabel1: "You have exceeded the maximum number of drafts allowed for warehouse receiving orders.",
      wroLimitReachedLabel2: "Please delete an existing draft to create a new one.",
      flatfileImport: "Done!  You successfully Loaded",
      flatFileImportRe: "Lines into your Warehouse Recieving Order",
      flatFileImportErrorInSku: "Your Load had errors on the following items",
      noSuccessInFlatFileImport: "There are no valid records to load from the file.",
      noValidFaltFile: "Your file format is incorrect please use a .csv file format. You can download a ",
      flatFileImportDateError: "The Date format is incorrect for the following product codes {{productCodes}}. Please ensure you are using mm/dd/yyyy",
      downalodSample: "template here",
      openWROList: "Go to open Wro List",
      productNotFound: "Product not found",
    },
    print: {
      imp: "Important",
      labelMsg: "Please ensure you affix the following labels on the outside of each box you are sending to the warehouse in",
      failureMsg: "Failure to follow this process could cause delays of putting away your product",
      confidentialMsg: "Confidential: This Label was generated for ",
      bxdPage1: "Boxout, LLC 2024 - Page 1",
      bxdPage2: "Boxout, LLC 2024 - Page 2",
      labelUncovered: "PLEASE LEAVE THIS LABEL UNCOVERED",
      warehouseReceving: "Warehouse Receiving: ",
      created: "Created: ",
      estimatedArrival: "Estimated Arrival Date: ",
      createdBy: "Created by: ",
      shipTo: "SHIP TO: "
    },
    reports: {
      title: 'Reports',
    },
    orders:{
      heading: 'Order Transaction History Summary',
      heading2: 'Order Transaction History Details',
      headingDetails: 'Order Transaction History Details',
      todaysOrders: "Today's Orders",
      totalOrders: 'Total Orders',
      distinct: 'Distinct',
      lines: 'Lines',
      units: 'Units',
      partiallyFulfilled: 'PARTIALLY FULFILLED',
      releasedToWarehouse: 'RELEASED TO WAREHOUSE',
      currentOpenOrder: 'Current Open Order',
      statusAsOf: 'Status as of',
      searchPlaceholder: 'Search by Order Number, PO, Name',
      multiple: 'Multiple',
      orderSnapshot: 'Order Snapshot',
      totalOpenOrders: 'Total Open Orders',
      openOrders: 'Open Orders'
    },
    reusuableFilter: {
      last28Days: 'Last 28 Days',
      last7Days: 'Last 7 Days',
      last60Days: 'Last 60 Days',
      last90Days: 'Last 90 Days',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
    orderDetails: {
      title: 'Order Transaction History',
      navigationBtn: 'Back to Search Results',
      orderNumber: 'ORDER NUMBER',
      orderedOn: 'ORDERED ON',
      orderedVia: 'ORDERED VIA',
      purchaseOrder: 'PURCHASE ORDER',
      clientPo: 'CLIENT PO #',
      clientSalesOrder: 'CLIENT SALES ORDER #',
      orderStatus: 'ORDER STATUS',
      shipTo: 'SHIP TO',
      address: 'Address',
      shippingMethod: 'Shipping Method',
      billTo: 'BILL TO',
      account: 'Account',
      orderLines: 'Order Lines',
      boxoutSku: 'Boxout SKU',
      mfgSku: 'MFG SKU',
      upc: 'UPC',
      lot: 'Lot',
      expDate: 'Exp Date',
      shipComplete: 'Ship Complete',
      clientLine: 'Client Line#',
      qty: 'QTY',
      arriving: 'Arriving',
    },
    returns: {
      title: 'Returns',
    },
    wroList: {
      title: 'Warehouse Receiving Orders',
      draft: 'Draft',
      draftBtn: 'Send Boxout Inventory (WRO)',
      receivedCompleteBtn: 'RECEIVED COMPLETE',
      draftTest: 'Boxout lets you have 5 drafts in any status. A draft that is older than 30 days will be deleted automatically',
      searchPlaceholder: 'Search by WRO',
      status: 'Status'
    },
  },
  salesrep: {
    login: {
      welcome: 'Welcome',
      subHeading: 'We are ready for your business!',
      dontHaveAccount: "Don't have an account?",
      getStartedNow: 'Get Started Now',
      or: 'or',
      call: ' call',
      forSupport: 'for support',
      for: 'for ',
      support: 'support',
      userIdLabel: 'User ID',
      salesRepIdLabel: 'Sales Rep ID',
      passwordLabel: 'Password',
      forgotPassword: 'Forgot Password',
      login: 'Login',
      userIdErrorMsg: 'Please enter your email address',
      passwordErrorMsg: 'Please enter your password',
      salesRepIdErrorMsg: 'Please enter your Sales Rep ID',
      passwordSuccessMsg: 'Done! The information has been updated',
      havingIssuesMsg: "If you're having issues accessing your account please call"
    },
    home: {
      search: 'Search all Data...',
      logout: 'v1.92012 | ©2022 Boxout, LLC',
    },
    graph:{
      mtd:'MTD',
      qtd:'QTD',
      ytd:'YTD',
      pace:'Pace',
      goal:'GOAL',
      growth:'Growth'
    },
    search:{
      noData: 'Please search for an organization',
      noResults: 'We\'re sorry, but we couldn\'t find any results for',
      resultsFor: 'Results for',
      orgnization: 'Organization',
      acct: 'Acct',
      acctRep: 'ACCT REP',
      relatedToParent: 'Related to parent',
      manage: 'Manage',
      manageAccount: 'Manage Account',
      primaryBillTo: 'PRIMARY BILL TO',
      primaryShipTo: 'PRIMARY SHIP TO',
      license: 'LICENSE',
      billing: 'BILLING',
      taxExempt: 'TAX EXEMPT',
      creditCard: 'CREDIT CARD',
      terms: 'TERMS',
      yes: 'YES',
      no: 'NO',
      insufficientInfo: 'User Credentials & Email address is missing. Order must be placed through Customer Service - Please call'
    },
    userImpersonate: {
      warning: 'Warning',
      subHeading: 'You are accessing a customers account',
      cancel: 'Cancel',
      continue: 'Continue',
      warningMsg: 'Please be aware any activity in this account is related to your actions and is being tracked.  Any transactions you are accountable for and the account owner should be notified.',
    },
    userSetting: {
      heading: 'User Settings',
      password: 'Password',
      cta: 'Change Password'
    },
    changePassword: {
      changePass: 'Change Password',
      cPassError: 'Please provide the current password',
      cPass: 'Current Password',
      newPass: 'New Password',
      rNewPass: 'Reenter New Password',
      createPass: 'Create Password',
      sspText: 'We use the best SSL technology to ensure our site is secure — Norton Security Services.'
    },
    asm:{
      asmText: 'ASM',
      asmFull: 'ASSISTED SALES MODE',
      close: 'Close'
    }
  },
  bulkPodExport: {
    header: 'Bulk Proof of Delivery Download',
    subHeading: 'Your proof of delivery bulk exports can be downloaded',
    podSearchHeading: 'Select Date Range',
    podWarning: 'You can query upto 31 days of data per download',
    generate: 'Generate',
    mandatoryEnd: 'Please enter a valid End Date',
    mandatoryStart: 'Please enter a valid Start Date',
    mandatoryAccount: 'Please select an Account',
    noPod: 'There currently is no POD available for this order.',
    podFailure: 'Error!  Please fix the fields identified that have errors.'
  },
  categorySpecificPage:{
    shopNow: 'Shop Now'
  },
  medicalInfo: {
    medicalDropshipInfo: 'Medical Drop Ship Information',
    upim: 'UPIN',
    patientUnique: 'Patient Unique Identifier *',
    validUpim : 'Please enter valid Patient Unique Identifier',
    npi: 'Please Enter the Prescribing Physician’s NPI Number',
    npiRequired: 'Please enter a NPI number',
    npiInfoHeader: 'NPI Information (10 Digits) *',
    npiValidError: 'Please enter a valid 10 digits NPI number',
    doctorsAddress: "Doctor's Address",
    cancel: 'Cancel',
    saveContinue: 'Save & Continue Checkout Process',
    medicalOrderDetails: 'Medical Order Details',
    npiHeader: 'NPI',
    saveAndContinue: 'Save & Continue',
    npiError: 'Note: The number could not be validated by the NPI Registry. '
  },
  
};
