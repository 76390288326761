import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService, AuthStorageService, USE_CLIENT_TOKEN, WindowRef } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthGuardService } from 'src/app/core/guards/auth-guard.service';
import { User } from 'src/app/core/models/user.model';
import * as AuthActions from 'src/app/core/store/actions/auth.actions';
import { ReusableSwitchAcctComponent } from 'src/app/shared/components/reusable-switch-acct/reusable-switch-acct.component';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CartService } from 'src/app/shared/services/common/cart.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import * as fromApp from '../../../store/app.reducer';
import { EmarsysTrackingService } from 'src/app/shared/services/common/emarsys-tracking.service';

@Component({
  selector: 'app-profile-top-header',
  templateUrl: './profile-top-header.component.html',
})
export class ProfileTopHeaderComponent implements OnInit, OnDestroy {
  user$: Observable<any | undefined>;
  showB2BUnitArrow: any = false;
  userId: any;
  currentB2bUnit: any;
  currentUser: any;
  currentCart: any;
  user: User;
  isAsmUserLoggedIn$: Observable<boolean> = of(false);
  isAsmUser:any;
  customerId:any;
  uId:any;
  userSub:any;
  constructor(
    private headerService: HeaderService,
    private auth: AuthService,
    private userAccount: UserAccountFacade,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private windowRef: WindowRef,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private customHttp: BoxoutEndpointService,
    private csAgentAuthService: CsAgentAuthService,
    private authGuardService: AuthGuardService,
    public cartService: CartService,
    private cookieService: CookieService,
    public authStorageService: AuthStorageService,
    public gtmService: GTMService,
    private emarsysTrackingService: EmarsysTrackingService
  ) {}

  ngOnInit(): void {
    this.user$ = this.auth.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          if (this.authStorageService.getItem('access_token')) {
            this.cookieService.set('ACCESS_TOKEN', this.authStorageService.getItem('access_token'));
          } else {
            this.cookieService.set('ACCESS_TOKEN', USE_CLIENT_TOKEN);
          }
          if(this.windowRef.localStorage?.getItem('loginClickedFromPDP') === 'true' || this.windowRef.localStorage?.getItem('isPDP') === 'true' ) {
            this.windowRef.localStorage?.removeItem('isPDP');
            this.windowRef.localStorage?.removeItem('loginClickedFromPDP');
            (this.windowRef as any).location.reload();
          }
          return this.userAccount.get();
        } else {
          return of(undefined);
        }
      })
    );

    const currentSite = this.headerService.getCurrentSite();
    this.currentUser = this.user$.subscribe((data) => {
      this.showB2BUnitArrow = false;
      this.user = data;
      
      if (this.user) {
        this.store.dispatch(AuthActions.loginSuccess({ user: this.user }));
        this.gtmService.setGTMUserData(this.user);
        const login = new Date();
        this.windowRef.localStorage?.setItem('loginTimestamp', login.toString());

        // For Emarsys loading script.
        this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
          const emearsysMerchantId = resp?.[`spartacus.config.${currentSite}.emarsys.merchant.id`];
          if(emearsysMerchantId) {
            this.emarsysTrackingService.loadEmarsysScript(emearsysMerchantId);
          }
        })
        
        this.customerId = this.user.orgUnit?.siebelRowId;
        this.uId = this.user.uid;
        this.emarsysTrackingService.getCustomerId(this.customerId, this.uId);
        if (!this.windowRef?.sessionStorage?.getItem('promotionsMap')) {
          this.getPromotionsMap();
        }
        if(this.windowRef?.sessionStorage?.getItem('newRegister')) {
          this.gtmService.setSignUpData(); // changes done as part of BCGI2-3095
          this.windowRef?.sessionStorage?.removeItem('newRegister');
        }
        
        if(this.windowRef?.sessionStorage?.getItem('loginSource')) {
          this.gtmService.setLoginData();
          setTimeout(() => {
            this.emarsysTrackingService.trackLoginEvents();
          }, 2000);
          this.windowRef?.sessionStorage?.removeItem('loginSource');
        }
      }
      this.userId = this.user?.displayUid;
      this.currentB2bUnit = this.user?.orgUnit?.uid;

      // For saving impersonated user email in session
      // For replacing current/uid
      if (
        this.windowRef?.localStorage?.getItem('asmLoggedInUserEmail') &&
        this.user?.displayUid
      ) {
        this.windowRef?.sessionStorage?.setItem(
          'impersonatedUserEmail',
          this.user?.displayUid || ''
        );
      }

      // For ASM userId replacement from request url with email
      if (
        this.windowRef?.localStorage?.getItem('asmLoggedInUserEmail') &&
        this.user?.uid
      ) {
        this.windowRef?.sessionStorage?.setItem(
          'impersonatedUserId',
          this.user?.uid || ''
        );
      }

      if (this.windowRef.localStorage?.getItem('multipleB2bUnitFlag')) {
        this.showB2BUnitArrow =
          this.windowRef.localStorage?.getItem('multipleB2bUnitFlag') === 'true'
            ? true
            : false;
        this.changeDetectorRef.detectChanges();
      }
    });

    //checking if there is cartid in sessionstorage, if not then call create cart API and eventually save cartid in session
    this.currentCart = this.user$.subscribe((data) => {
      this.user = data;
      if (this.user && !this.windowRef.sessionStorage?.getItem('cartId')) {
        this.cartService.getCart(this.user?.uid);
      }
    });

    // For checking ASM user login status
    this.isAsmUserLoggedIn$ =
      this.csAgentAuthService.isCustomerSupportAgentLoggedIn();
  }
  /**
   * This function will get the product-promotion map and store in session
   */
  getPromotionsMap() {
    this.customHttp
      .get(`getBogoPromotions?fields=DEFAULT`)
      .subscribe((data: any) => {
        this.windowRef.sessionStorage?.setItem(
          'promotionsMap',
          JSON.stringify(data)
        );
      });
  }

  // userLoggedIn() {
  // DO NOT DELETE - needed for store implementation
  // this.store.dispatch(
  //   AuthActions.loginSuccess({ user: this.userData })
  // );
  // this.headerService.sendData(this.user$);
  // }

  openB2bUnitModal() {
    this.dialog.open(ReusableSwitchAcctComponent, {
      data: { userId: this.userId, b2bunit: this.currentB2bUnit },
      panelClass: ['custom-dialog-size'],
      width:'730px',
    });
  }
  redirectToASMLoggedOutUser(){
      this.router.navigate(['/sales-rep/login']);
  }
  logout() {
    this.windowRef.localStorage?.setItem('isLogoutClicked','true');
    this.windowRef.localStorage?.removeItem('loggedInFrom');
    const isAsmUserLoggedIn = this.windowRef.localStorage?.getItem(
      'asmLoggedInUserEmail'
    );
    this.isAsmUser = isAsmUserLoggedIn;
    if (isAsmUserLoggedIn) {
      this.resetStorage();
      this.csAgentAuthService.logoutCustomerSupportAgent().finally(() => {
        this.redirectToASMLoggedOutUser();
        this.windowRef.localStorage?.removeItem('asmLoggedInUserEmail');
        //(this.windowRef as any).location.reload();
      });
    } else {
      this.router
        .navigateByUrl('/logout')
        .then(() => {
          this.resetStorage();
          const isAsmUserLoggedIn = this.windowRef.localStorage?.getItem(
            'asmLoggedInUserEmail'
          );
          if (isAsmUserLoggedIn) {
            this.csAgentAuthService.logoutCustomerSupportAgent().finally(() => {
              this.redirectToLoggedOutUser();
              this.windowRef.localStorage?.removeItem('asmLoggedInUserEmail');
              (this.windowRef as any).location.reload();
            });
          } else {
            this.store.dispatch(AuthActions.logout());
            this.redirectToLoggedOutUser();
          }
          this.emarsysTrackingService.trackLogoutEvents();
        })
        .finally(() => {
          this.redirectToLoggedOutUser();
        });
    }
  }

  redirectToLoggedOutUser = () => {
    this.userSub = this.auth.isUserLoggedIn().pipe(take(1)).subscribe((isLoggedIn: boolean) => { // adding the take (1) to stop multiple subscribe of this while login and logout
      if (!isLoggedIn) {
        if (this.authGuardService.isObagiOrMuSite()) {
          this.router.navigate(['/login']);
        } else if(this.isAsmUser){
          this.router.navigateByUrl('/sales-rep/login');
        }else {
          this.router.navigate(['']);
        }
      }
    });
  };

  resetStorage() {
    this.windowRef.localStorage?.removeItem('b2bUnit');
    this.headerService?.setAddCardCount(null);
    this.windowRef.sessionStorage?.removeItem('cartId');
    this.windowRef.sessionStorage?.removeItem('viewBy');
    this.windowRef.localStorage?.removeItem('multipleB2bUnitFlag');
    this.windowRef.sessionStorage?.removeItem('promotionsMap');
    // For removing uid and email for impersonated user from localStorage
    this.windowRef.sessionStorage?.removeItem('impersonatedUserId');
    this.windowRef.sessionStorage?.removeItem('impersonatedUserEmail');
    this.windowRef.localStorage?.removeItem('goToUrl');
    this.windowRef.sessionStorage?.removeItem('recentOrderDate');
    this.windowRef.sessionStorage?.removeItem('orderApprovalCount');
    this.windowRef.localStorage?.setItem('loggedOut', 'true');
    this.windowRef.localStorage?.removeItem('_grecaptcha');
    this.windowRef.localStorage?.removeItem('isEmailUrl');
    this.windowRef.sessionStorage?.removeItem('isSiebelCallNeeded');
    this.windowRef.sessionStorage?.removeItem('loginSource');
    this.windowRef.sessionStorage?.removeItem('newRegister');
    this.windowRef.sessionStorage?.removeItem('checkoutInProgess')
    this.cookieService.delete('ACCESS_TOKEN');
    this.windowRef.localStorage?.removeItem('loginTimestamp');
  }
  ngOnDestroy() {
    this.currentUser.unsubscribe();
    this.currentCart?.unsubscribe();
    this.userSub?.unsubscribe();
  }
}
