import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmarsysTrackingService {
  uid?: string;
  latestSiebelId?: string;
  emarsysCartPayload: any = [];
  public scriptLoaded= false;
  public cartDetailsSubject = new BehaviorSubject<any>(null);
  cartDetails$ = this.cartDetailsSubject.asObservable();

  constructor(private window: WindowRef, @Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private document: Document,) {
    this.cartDetails$.subscribe((cartDetails) => {
      if (cartDetails) {
        this.emarsysCartPayload = cartDetails?.entries ?? cartDetails ?? [];
      }
    });
  }

  getCustomerId(userId: string, siebelId: string): void {
    this.uid = userId;
    this.latestSiebelId = siebelId;
  }

  loadEmarsysScript(emearsysMerchantId:any): void {
    if (isPlatformBrowser(this.platformId) && emearsysMerchantId) {
      const scriptId = 'scarab-js-api';
      if (!this.document.getElementById(scriptId)) {
        const scarabScript = this.document.createElement('script');
        // Use the merchantId in the script URL
        scarabScript.src = `//cdn.scarabresearch.com/js/${emearsysMerchantId}/scarab-v2.js`;
        scarabScript.id = scriptId;
        scarabScript.onload = () => {
          this.scriptLoaded = true;
        }
        this.document.head.appendChild(scarabScript);
      } 
      else {
        this.scriptLoaded = true; 
      }
    }
  }

  trackLoginEvents(): void {
    const ScarabLoginQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    const loginTimestamp = (
      this.window.nativeWindow as Window & { localStorage: Storage }
    ).localStorage.getItem('loginTimestamp');
    ScarabLoginQueue.push(['tag', 'loginTimestamp', { time: loginTimestamp }]);
    const customerId = `${this.uid}${this.latestSiebelId}`;
    ScarabLoginQueue.push(['setCustomerId', customerId]);
    ScarabLoginQueue.push(['cart', this.emarsysCartPayload ? this.formatCartData(this.emarsysCartPayload) : []]);
    ScarabLoginQueue.push(['go']);
  }

  trackLogoutEvents(): void {
    const ScarabLogoutQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    const logout = new Date();
    const logoutTimestamp = logout.toString();
    ScarabLogoutQueue.push(['tag', 'logoutTimestamp', { time: logoutTimestamp }]);
    const customerId = `${this.uid}${this.latestSiebelId}`;
    ScarabLogoutQueue.push(['setCustomerId', customerId]);
    ScarabLogoutQueue.push(['cart', this.emarsysCartPayload ? this.formatCartData(this.emarsysCartPayload) : []]);
    ScarabLogoutQueue.push(['go']);
  }

  trackCart(currentCartItem: any[]): void {
    const ScarabTracCartQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    ScarabTracCartQueue.push(['cart', currentCartItem ? this.formatCartData(currentCartItem) : []]);
    if (this.latestSiebelId && this.uid) {
      const customerId = `${this.uid}${this.latestSiebelId}`;
      ScarabTracCartQueue.push(['setCustomerId', customerId]);
    }
    ScarabTracCartQueue.push(['go']);
  }

  trackPdpEvents(itemId:any): void {
    const ScarabTrackPdpQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    const loginTimestamp = (
      this.window.nativeWindow as Window & { localStorage: Storage }
    ).localStorage.getItem('loginTimestamp');

    ScarabTrackPdpQueue.push(['tag', 'loginTimestamp', { time: loginTimestamp }]);

    if (this.latestSiebelId && this.uid) {
      const customerId = `${this.uid}${this.latestSiebelId}`;
      ScarabTrackPdpQueue.push(['setCustomerId', customerId]);
    }
    if (itemId) {
      ScarabTrackPdpQueue.push(['view', itemId]);
      ScarabTrackPdpQueue.push(['cart', this.emarsysCartPayload ? this.formatCartData(this.emarsysCartPayload) : []]);
    }
    ScarabTrackPdpQueue.push(['go']);
  }

  trackPurchaseEvents(emarsysPurchasePayload: any, orderId:any): void {
    const ScarabTrackPurchaseQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    ScarabTrackPurchaseQueue.push([
      'purchase',
      {
        orderId: orderId,
        items: this.formatCartData(emarsysPurchasePayload)
      }
    ]);
    if (this.latestSiebelId && this.uid) {
      const customerId = `${this.uid}${this.latestSiebelId}`;
      ScarabTrackPurchaseQueue.push(['setCustomerId', customerId]);
    }
    ScarabTrackPurchaseQueue.push(['cart', []]); // As per documentation this need to be passed as empty.
    ScarabTrackPurchaseQueue.push(['go']);
  }

  trackBrandEvents(brandName:string): void {
    const ScarabTrackBrandQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    ScarabTrackBrandQueue.push(['tag', 'brand', { brand: brandName }]); // Todo: Brand name fetch
    
    if (this.latestSiebelId && this.uid) {
      const customerId = `${this.uid}${this.latestSiebelId}`;
      ScarabTrackBrandQueue.push(['setCustomerId', customerId]);
    }
    ScarabTrackBrandQueue.push(['cart', this.emarsysCartPayload ? this.formatCartData(this.emarsysCartPayload) : []]);

    ScarabTrackBrandQueue.push(['go']);
  }

  trackFavoriteProduct(gaItems: any): void {
    const ScarabFavoriteQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    ScarabFavoriteQueue.push(['tag', 'favorite', { items: gaItems[0]?.item_name }]);
    if (this.latestSiebelId && this.uid) {
      const customerId = `${this.uid}${this.latestSiebelId}`;
      ScarabFavoriteQueue.push(['setCustomerId', customerId]);
    }
    ScarabFavoriteQueue.push(['cart', this.emarsysCartPayload ? this.formatCartData(this.emarsysCartPayload) : []]);

    ScarabFavoriteQueue.push(['go']);
  }

  trackCategoryEvents(categoryCode:any): void {
    const ScarabCategoryQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    ScarabCategoryQueue.push(['category', categoryCode]);
    if (this.latestSiebelId && this.uid) {
      const customerId = `${this.uid}${this.latestSiebelId}`;
      ScarabCategoryQueue.push(['setCustomerId', customerId]);
    }
    ScarabCategoryQueue.push(['cart', this.emarsysCartPayload ? this.formatCartData(this.emarsysCartPayload) : []]);
    ScarabCategoryQueue.push(['go']);
  }

  trackSearchEvents(textToSearch:any): void {
    const ScarabSearchQueue = (this.window.nativeWindow as any).ScarabQueue || [];
    ScarabSearchQueue.push(['searchTerm', textToSearch]);
    if (this.latestSiebelId && this.uid) {
      const customerId = `${this.uid}${this.latestSiebelId}`;
      ScarabSearchQueue.push(['setCustomerId', customerId]);
    }
    ScarabSearchQueue.push(['cart', this.emarsysCartPayload ? this.formatCartData(this.emarsysCartPayload) : []]);
    ScarabSearchQueue.push(['go']);
  }

  formatCartData(cartData: any): any {
    const cartItems: { item: any; price: any; quantity: any; }[] = [];
    if(Array.isArray(cartData)){
      cartData?.forEach((cartItem: any) => {
        const item = {
          item: cartItem.product?.code || cartItem.item,
          price: cartItem.basePrice?.value || cartItem.price,
          quantity: cartItem.quantity
        };
        cartItems.push(item);
      });
    }
    return cartItems;
  }

}
